<script setup>
import {
	IonPage,
	IonContent,
	loadingController,
	toastController,
	modalController,
} from '@ionic/vue';
import '@/utils/register-service-worker';
import { supabase } from '@/utils/supabase';
import { computed, onMounted, reactive } from 'vue';
import dayjs from 'dayjs';
import { trackEvent } from '@/utils/analytics';

import LilaCat from './_components/LilaCat.vue';
import WeekChart from './_components/WeekChart.vue';
import { formatDate, getDayDifference, getDayNow } from '@/utils/dateRange';
import TransactionsGrid from './_components/TransactionsGrid.vue';
import QuizDialog from './_components/outer_loop/QuizDialog.vue';

const state = reactive({
	spent: null,
	startDate: null,
	endDate: null,
	spendingForecast: null,
	nextPeriodExists: null,
	amountLeft: null,
	dailyIncrease: null,
});
const percentSpent = computed(() => state.spent / state.spendingForecast);

const fetchSpendHistory = async (session) => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 8000 });
	const dateNow = getDayNow();

	try {
		await loader.present();
		const { data, error } = await supabase
			.from('spend_history_w_totals')
			.select(
				'start_date, end_date, spending_forecast, spent, next_period_exists'
			)
			.lte('start_date', dateNow)
			.gte('end_date', dateNow)
			.eq('user_id', session.user.id);

		if (error) {
			throw error;
		}

		if (!data.length) {
			launchOuterLoop();
			return;
		}

		const {
			start_date,
			end_date,
			spent,
			spending_forecast,
			next_period_exists,
		} = data[0];
		state.startDate = formatDate(start_date, 'start');
		state.endDate = formatDate(end_date, 'end');
		state.spendingForecast = spending_forecast;
		state.spent = spent;
		state.nextPeriodExists = next_period_exists;
		state.amountLeft = (spending_forecast - spent).toFixed(2);
		state.dailyIncrease = (
			spending_forecast / getDayDifference(state.startDate, state.endDate)
		).toFixed(2);
	} catch (err) {
		trackEvent('Error loading MainPage', err);
		toast.message = err.message;
		await toast.present();
	} finally {
		loader.dismiss();
	}
};

const launchOuterLoop = async () => {
	const modal = await modalController.create({
		component: QuizDialog,
		initialBreakpoint: 0.75,
		breakpoints: [0.75, 1],
		backdropDismiss: false,
		handleBehavior: 'cycle',
	});

	modal.present();
};

onMounted(async () => {
	// TODO swap to getUser()

	const { data } = await supabase.auth.getSession();
	fetchSpendHistory(data.session).then(() => {
		const lastDay = dayjs().isSame(state.endDate, 'day');
		if (lastDay && !state.nextPeriodExists) {
			launchOuterLoop();
		}
	});
});
</script>

<template>
	<ion-page>
		<ion-content>
			<div v-if="state.spent != null">
				<LilaCat :percentSpent="percentSpent" />
				<WeekChart
					:startDate="state.startDate"
					:endDate="state.endDate"
					:forecast="state.spendingForecast" />
				<ul class="details">
					<li class="spent-amount">
						<span>Total spent:</span><span>${{ state.spent }}</span>
					</li>
					<li>
						<span>Amount left:</span>
						<span>${{ state.amountLeft }}</span>
					</li>
					<li>
						<span>Daily goal:</span>
						<span>${{ state.dailyIncrease }}</span>
					</li>
				</ul>
				<TransactionsGrid
					:startDate="state.startDate"
					:endDate="state.endDate" />
			</div>
		</ion-content>
	</ion-page>
</template>

<style scoped>
.center {
	margin: auto;
	width: fit-content;
}
.details {
	width: fit-content;
	margin: auto;
	list-style: none;
	padding-left: 0;
	padding: 1rem;
}

.details li {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 1rem;
}
</style>
