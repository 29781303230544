import { ref } from 'vue';
import { supabase } from './supabase';
const displayError = ref();
const session = ref(null);

/**
 * THIS CONNECTS TO SUPABASE...
 */
const dataService = () => {
	/**
	 * called when dataService is first loaded to initialize
	 * the session, also sets a listener to update the session
	 * based on changes in the auth state
	 */
	const initialize = async () => {
		supabase.auth.getSession().then(({ data }) => {
			session.value = data.session;
		});
		supabase.auth.onAuthStateChange((_event, _session) => {
			// console.log("session log", { _event, _session });
			session.value = _session;
		});
	};

	/**
	 *
	 */
	const login = async (email, password) => {
		let { data: user, error } = await supabase.auth.signInWithPassword({
			email,
			password,
		});
		console.log('login', { user, error });
		return { user, error };
	};

	/**
	 * Login with magic link!
	 */
	const loginWithMagicLink = async (email) => {
		let { error } = await supabase.auth.signInWithOtp({
			email: email.value,
			options: {
				emailRedirectTo: 'http://localhost:8100/splash',
			},
		});
		console.log('loginWithMagicLink', { error });
		return { error };
	};

	/**
	 *
	 */
	const logout = async () => {
		let { data: user, error } = await supabase.auth.signOut();
		console.log('logout', { user, error });
		return { user, error };
	};

	/**
	 *
	 */
	const forgotPassword = async (email) => {
		let { data: user, error } =
			await supabase.auth.api.resetPasswordForEmail(email);
		console.log('forgotpassword', { error, user });
		return error;
	};

	const changePassword = async (password) => {
		const { data: user, error } = await supabase.auth.update({
			password,
			data: {
				password_changed: new Date(),
			},
		});
		console.log('changePassword error', { user, error });
		return error;
	};

	/**
	 *
	 */
	const createAccount = async (email, password) => {
		let { data, error } = await supabase.auth.signUp({
			email,
			password,
		});
		const { user, session } = data;
		console.log('createAccount', { user, error, session });
		return { user, error };
	};

	/**
	 *  Resend an email signup confirmation
	 */
	const resendEmailSignUpConfirmation = async (email) => {
		let { data: user, error } = await supabase.auth.resend({
			type: 'signup',
			email,
		});
		console.log('resendEmailSignUpConfirmation', { error, user });
		return error;
	};

	const setUserDetails = async (details = {}) => {
		const { data: oldData, error } = await supabase
			.from('user_info')
			.select()
			.eq('user_id', session.value.user.id);

		const { data: newData, error: upsertError } = await supabase
			.from('user_info')
			.upsert({
				...oldData[0],
				user_id: session.value.user.id,
				email: session.value.user.email,
				...details,
			})
			.select();

		if (upsertError || error)
			throw new Error('error', upsertError || error);
		return newData;
	};

	const getUserDetails = async () => {
		const { data, error } = await supabase
			.from('user_info')
			.select()
			.eq('user_id', session.value.user.id);

		// console.log("getUserDetails", { data, error })

		if (error) throw new Error('error', error);
		else if (data.length > 0) return data[0];
		else return {};
	};

	return {
		// PROPERTIES
		displayError,
		getUser: () => {
			if (session.value && session.value.user) return session.value.user;
			return supabase.auth.getSession().then(({ data }) => {
				if (data.session) return data.session.user;
				return null;
			});
		},

		// FUNCTIONS
		getUserDetails,
		setUserDetails,
		login,
		loginWithMagicLink,
		createAccount,
		logout,
		initialize,
		forgotPassword,
		changePassword,
		resendEmailSignUpConfirmation,
	};
};

dataService().initialize();

// export the service
export default dataService;
