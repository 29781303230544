<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-title>Feel Good Finance</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<div class="ion-padding">
				<p>Reset Password</p>
			</div>
			<ion-img
				class="email-img"
				src="assets/EmailSent.svg"
				alt="Email Sent Image"></ion-img>
			<ion-list :inset="true">
				<form @submit.prevent="handleResetPassword">
					<div class="form-item-container">
						<ion-item>
							<ion-label position="stacked">Password</ion-label>
							<ion-input
								class="custom"
								v-model="state.password"
								name="password"
								autocomplete="on"
								type="password"></ion-input>
						</ion-item>
						<div
							v-if="
								v$.password.$invalid &&
								v$.password.minLength.$message
							"
							class="error-text">
							Please enter a valid password with:
							<ion-list class="bullet-list" lines="none">
								<ion-item class="error-text"
									>A min length of 6 characters</ion-item
								>
								<ion-item class="error-text"
									>At least 1 uppercase character</ion-item
								>
								<ion-item class="error-text"
									>At least 1 lowercase character</ion-item
								>
								<ion-item class="error-text"
									>At least 1 number (eg: 1,2,3,4)</ion-item
								>
								<ion-item class="error-text"
									>At least 1 special character (eg:
									!$^*~)</ion-item
								>
							</ion-list>
						</div>
					</div>
					<div class="ion-text-center">
						<ion-button class="submit-btn" type="submit"
							>Reset Password</ion-button
						>
					</div>
				</form>
			</ion-list>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonLabel,
	IonInput,
	IonButton,
	toastController,
	loadingController,
} from '@ionic/vue';
import { defineComponent, reactive, computed } from 'vue';
import useValidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import {
	containsUppercase,
	containsLowercase,
	containsNumber,
	containsSpecial,
} from '@/utils/validations';
import dataService from '@/utils/session';
const { changePassword } = dataService();
import { trackEvent } from '@/utils/analytics';

export default defineComponent({
	name: 'ResetPasswordPage',
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonList,
		IonItem,
		IonLabel,
		IonInput,
		IonButton,
	},
	setup() {
		const state = reactive({
			password: '',
		});

		const rules = computed(() => {
			return {
				password: {
					required,
					minLength: minLength(6),
					containsUppercase,
					containsLowercase,
					containsNumber,
					containsSpecial,
				},
			};
		});

		const v$ = useValidate(rules, state);
		return { state, v$ };
	},
	data() {
		return {
			password: '',
		};
	},
	methods: {
		async handleResetPassword() {
			trackEvent('Reset password submitted');
			const loader = await loadingController.create({});
			const toast = await toastController.create({ duration: 5000 });

			const valid = await this.v$.$validate();
			if (!valid) {
				return;
			}

			try {
				await loader.present();
				const error = await changePassword(this.state.password);
				if (!error) {
					toast.message = `Password was reset!`;
					trackEvent('Reset password worked');
					await toast.present();
				} else {
					throw new Error(error);
				}
			} catch (error) {
				trackEvent('Reset password error', error);
				toast.message = error.error_description || error.message;
				await toast.present();
			} finally {
				await loader.dismiss();
			}
		},
	},
});
</script>

<style scoped>
.submit-btn {
	width: 100%;
	height: 56px;
	font-size: medium;
	--background: #28ba62;
	--background-hover: #42d77d;
	--background-focused: #42d77d;
	--color: #ffffff;
	--border-radius: 0;
}

.bottom-btn {
	font-size: medium;
	width: 123px;
	height: 56px;
	margin-top: 20px;
}

.resend-btn {
	width: 100%;
	height: 56px;
	font-size: medium;
	--background: #28ba62;
	--background-hover: #42d77d;
	--background-focused: #42d77d;
	--color: #ffffff;
	--border-radius: 0;
}

.email-img {
	width: 295px;
	height: 295px;
}

ion-input.custom {
	--color: #222428;
}

ion-item.item-input {
	padding-bottom: 8px;
}

.form-item-container {
	padding-bottom: 52px;
}
.error-text {
	font-size: x-small;
	color: #ab3030;
	--min-height: 16px;
}
</style>
