<script setup>
import { ref, computed, onMounted } from 'vue';
import {
	IonContent,
	modalController,
	IonHeader,
	IonToolbar,
	IonButtons,
	toastController,
} from '@ionic/vue';
import dayjs from 'dayjs';
import { trackEvent } from '@/utils/analytics';
import { supabase, getUser } from '@/utils/supabase';
import { createFirstDay, createLastDay } from '@/utils/dateRange';
import EndWeek from './EndWeek.vue';
import QuizState from './QuizState.vue';
import CalculatingLoader from './CalculatingLoader.vue';
import StartWeek from './StartWeek.vue';

const State = Object.freeze({
	EndWeek: 1,
	Quiz: 2,
	Calculating: 3,
	StartWeek: 4,
});
const state = ref(null);
const endingPeriod = ref(null);
const nextSpendingPeriod = ref(null);
const chartData = ref();
const user = ref(null);

const lastPeriodEnded = computed(() =>
	dayjs().isAfter(endingPeriod.value.end_date, 'day')
);

const nextStep = async () => {
	state.value++;

	if (state.value === State.Calculating) {
		// set chart for State.StartWeek
		chartData.value.endDate = nextSpendingPeriod.value.end_date;
		chartData.value.forecast += nextSpendingPeriod.value.spending_forecast;

		// save badges from last week
		try {
			const badgesEarned = Object.values(
				endingPeriod.value.new_badges_earned
			).map((v) => ({ ...v, user_id: user.value.id }));
			const { error } = await supabase
				.from('badges')
				.upsert(badgesEarned);
			if (error) throw error;
		} catch (e) {
			trackEvent('Error upserting new badges', e);
		}
	}
};

onMounted(async () => {
	const toast = await toastController.create({ duration: 8000 });
	user.value = await getUser();

	try {
		const { data, error } = await supabase.rpc('outer_loop_data', {
			user_id: user.value.id,
		});
		if (error) throw error;
		const { data: target } = await supabase
			.from('user_info')
			.select('avg_weekly_target')
			.eq('user_id', user.value.id)
			.single();

		// ASSUMPTION: supabase data is sorted in descending order
		endingPeriod.value = data[0];
		nextSpendingPeriod.value = {
			user_id: user.value.id,
			start_date: createFirstDay(),
			end_date: createLastDay(),
			spending_forecast: endingPeriod.value.next_forecast,
			quiz_special_spending: 0,
			quiz_anticipate_spending: 0,
			quiz_spending_phase: false,
			target_weekly_goal: target.avg_weekly_target,
		};

		// First ever week goes straight to Quiz
		if (data[0].week_num == null) {
			state.value = State.Quiz;
			return;
		}

		chartData.value = {
			startDate: data[data.length - 1].start_date,
			endDate: data[0].end_date,
			forecast: data[0].total_forecast,
		};

		// load the view
		state.value = State.EndWeek;
	} catch (e) {
		trackEvent('Error in QuizDialog', e);
		toast.message =
			'Kitty tears :( We were unable to load your weekly quiz. Please close and reopen the app';
		await toast.present();
	}
});
</script>

<template>
	<ion-header v-if="state == State.EndWeek && !lastPeriodEnded">
		<ion-toolbar>
			<ion-buttons slot="end">
				<ion-button @click="modalController.dismiss()">
					Cancel
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content
		class="quiz-dialog ion-padding"
		@scroll="$event.stopPropagation()">
		<div v-if="state === State.EndWeek">
			<EndWeek
				:endingPeriod="endingPeriod"
				:chartData="chartData"
				@next-step="nextStep" />
			<span class="scroll-height" />
		</div>
		<div v-if="state === State.Quiz">
			<QuizState
				:nextSpendingPeriod="nextSpendingPeriod"
				@next-step="nextStep" />
			<span class="scroll-height" />
		</div>
		<div v-if="state === State.Calculating">
			<CalculatingLoader @next-step="nextStep" />
			<span class="scroll-height" />
		</div>
		<div v-if="state === State.StartWeek">
			<StartWeek
				:endingPeriod="endingPeriod"
				:nextSpendingPeriod="nextSpendingPeriod"
				:chartData="chartData" />
			<span class="scroll-height" />
		</div>
	</ion-content>
</template>

<style scoped>
.scroll-height {
	display: block;
	height: 2.75rem; /* height of ion-button + spacing */
}
</style>
