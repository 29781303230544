<script setup>
import { IonPage, IonContent } from '@ionic/vue';
import { onMounted } from 'vue';
import { supabase } from '@/utils/supabase';
import { loadingController, toastController, useIonRouter } from '@ionic/vue';
import { identifyUser, trackUser } from '@/utils/analytics';
const ionRouter = useIonRouter();

onMounted(async () => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 5000 });

	try {
		loader.present();

		const params = new URLSearchParams(window.location.hash.substring(2));

		const accessToken = params.get('access_token');
		const expiresAt = params.get('expires_at');
		const expiresIn = params.get('expires_in');
		const refreshToken = params.get('refresh_token');
		const tokenType = params.get('token_type');
		const type = params.get('type');

		if (!accessToken) {
			throw new Error('No access token found in the URL.');
		}

		// Use the token to sign in the user
		const { data, error } = await supabase.auth.setSession({
			access_token: accessToken,
			refresh_token: refreshToken,
		});

		if (error) {
			throw new Error(error.message);
		}

		// Identify and track the user with additional parameters if needed
		identifyUser(data.session);
		trackUser(data.session, 'MAGIC_LINK_SIGN_IN', {
			expires_at: expiresAt,
			expires_in: expiresIn,
			token_type: tokenType,
			type: type,
		});

		// Redirect to the home page or a specific page
		ionRouter.push('/');
	} catch (err) {
		toast.message = err.message || 'An error occurred during sign-in.';
		await toast.present();
	} finally {
		loader.dismiss();
	}
});
</script>

<template>
	<ion-page>
		<ion-content>
			<p>Processing Magic Link...</p>
		</ion-content>
	</ion-page>
</template>
