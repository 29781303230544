<script setup>
import { onMounted } from 'vue';
import moneykit from '@moneykit/connect';

onMounted(() => {
	const url = process.env.VUE_APP_SERVER_BACKEND_URL + `/api?data=`;
	fetch(url + encodeURIComponent(window.location.href));

	// Reconstruct the redirect_uri to match MoneyKit's
	const params = new URLSearchParams(window.location.hash.substring(7));
	const redirect_uri = `${window.location.origin}/moneykit?${params.toString()}`;

	const mk = new moneykit();
	mk.continue(redirect_uri, (exchangeToken, institution) => {
		console.log('oauth continue', exchangeToken, institution);
		fetch(url + encodeURIComponent({ exchangeToken, institution }));
	});
});
</script>

<template>hello world</template>
