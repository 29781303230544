const graphOptions = {
	xAxis: {
		type: 'time',
		axisPointer: {
			show: true,
			lineStyle: {
				width: 2,
				color: '#DEE4F5',
				type: 'dashed',
			},
		},
	},
	yAxis: {
		show: false,
	},
	grid: {
		top: 1,
		left: 10,
		right: 0,
		bottom: 20,
	},
	tooltip: {
		trigger: 'axis',
		padding: [16, 16, 8, 16],
		confine: true,
		enterable: true,
		backgroundColor: 'rgb(255, 255, 255)',
		extraCssText:
			'pointer-events: all; overflow: auto; max-width: 400px; white-space: normal; box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, .1); border-radius: 4px; max-height: 200px;',
		textStyle: {
			color: '#333',
			fontSize: 12,
			fontFamily:
				'-apple-system, BlinkMacSystemFont, Segoe UI, "PingFang SC", "Helvetica Neue", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif',
		},
	},
	series: [
		{
			name: 'Forecast',
			data: [],
			type: 'line',
			showSymbol: false,
			color: 'darkgrey',
			lineStyle: { type: 'dotted' },
			markPoint: {
				data: [
					{
						symbol: 'image://data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><text y="20" font-size="20">🎁</text></svg>',
						symbolOffset: ['-35%', '35%'], // Adjust this to move the symbol down and left
						symbolSize: [24, 24],
						label: { show: false },
					},
				],
			},
		},
		{
			name: 'Spent',
			data: [],
			type: 'line',
			step: 'end',
			color: '#8754b3',
			showSymbol: false,
			markLine: {
				label: {
					show: true,
				},
				data: [{ name: 'Imp Day 01', xAxis: '2022-11-28' }],
			},
			markPoint: {
				data: [
					{
						symbol: 'image://data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><text y="20" font-size="20">😺</text></svg>',
						symbolSize: [24, 24],
						label: { show: false },
					},
				],
			},
		},
	],
};

export default graphOptions;
