import { trackEvent } from '@/utils/analytics';
import { getUser } from '@/utils/supabase';

/**
 * This is the update code, we have no way to test it yet
 * We don't know what to update that will trigger this
 */
const swUpdateCheck = (existingRegistration) => {
	// When an update is found to the service worker script
	existingRegistration.addEventListener('updatefound', () => {
		// Get the installing service worker
		const installingWorker = existingRegistration.installing;
		installingWorker.onstatechange = () => {
			// Once the installing sw is installed...
			if (installingWorker.state === 'installed') {
				// ...and the sw is active on this page...
				if (navigator.serviceWorker.controller) {
					// ...update the registration
					existingRegistration.update();
				}
			}
		};
	});
};

const initSWData = (registration, user_id) => {
	registration.active.postMessage({
		env: process.env.VUE_APP_ENV,
		userId: user_id,
		postHogKey: process.env.VUE_APP_POSTHOG_API_KEY,
	});
};

const registerSW = async (user_id) => {
	navigator.serviceWorker
		.register('/firebase-messaging-sw.js')
		.then(async (registration) => {
			initSWData(registration, user_id);
			swUpdateCheck(registration);
		})
		.catch((error) => {
			trackEvent('Notifications serviceworker registration failed', {
				user_id,
				error,
			});
		});
};

if ('serviceWorker' in navigator) {
	window.addEventListener('load', async () => {
		const user = await getUser();
		navigator.serviceWorker
			.getRegistration('/firebase-messaging-sw.js')
			.then((existingRegistration) => {
				if (existingRegistration) {
					initSWData(existingRegistration, user?.id);
					swUpdateCheck(existingRegistration);
				} else {
					// Register a new service worker if none exists
					registerSW(user?.id);
				}
			})
			.catch((error) => {
				trackEvent('No notification-service-worker present', {
					user_id: user?.id,
					error,
				});
			});
	});
}
