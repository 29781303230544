const cats = {
	chill: [
		{
			img: 'assets/cat/chill_sleep.png',
			alt: 'Lila the cat is sleeping on a pillow',
		},
		{
			img: 'assets/cat/chill_floor.PNG',
			alt: 'Lila the cat is paws-up waiting for scratches',
		},
		{
			img: 'assets/cat/chill_stretch.png',
			alt: 'Lila the cat is stretchingggg with flowers around her!',
		},
		{
			img: 'assets/cat/chill_sit.png',
			alt: 'Lila the cat is sitting with a wide smile',
		},
	],
	hangingout: [
		{
			img: 'assets/cat/hangingout_gym.png',
			alt: 'Lila the cat is flexing her muscles at the gym',
		},
		{
			img: 'assets/cat/hangingout_party.PNG',
			alt: 'Lila the cat is at the party, wearing a party cat with sparkles all around her',
		},
		{
			img: 'assets/cat/hangingout_butt.png',
			alt: 'Lila the cat is chilling in front of the tv giving us a butt view',
		},
		{
			img: 'assets/cat/hangingout_super.png',
			alt: 'Lila the cat is jumping in the air with her superhero costume on!',
		},
	],
	worried: [
		{
			img: 'assets/cat/worried_smoke.png',
			alt: "Lila's credit card is smoking and she's looking at it quite worried",
		},
		{
			img: 'assets/cat/worried_frazzled.png',
			alt: 'Lila the cat is frazzled! Her back is arched and all her furr is sticking up',
		},
		{
			img: 'assets/cat/worried_wiggly.PNG',
			alt: "Lila's eyes are black holes and she's cartoonly woozy",
		},
		{
			img: 'assets/cat/worried_hiding.PNG',
			alt: 'Lila is hiding in a cardboard box looking scared',
		},
	],
	upset: [
		{
			img: 'assets/cat/upset_villain.PNG',
			alt: 'Lila is plotting something with the look of villainy in her eyes',
		},
		{
			img: 'assets/cat/upset_rampage.PNG',
			alt: 'Lila is muahahaha-ing with her claws sharp and evil',
		},
		{
			img: 'assets/cat/upset_thisisfine.png',
			alt: "Lila is sipping coffee in a room of fire as if saying 'this is fine'",
		},
		{
			img: 'assets/cat/upset_fire.PNG',
			alt: "Lila's credit card caught fire and she's looking at it quite worried",
		},
	],
};

export default cats;
