import dayjs from 'dayjs';

export const convertMonthGoalToWeekGoal = (week_goal) => (week_goal * 12) / 52;

export const convertWeekGoalToMonthGoal = (week_goal) =>
	Math.round((week_goal * 52) / 12).toFixed(2);

export const spendDifference = (goal, spent) => {
	if (!goal) return {};
	const value = goal - spent;
	return {
		string: (value > 0 ? '+$' : '-$') + Math.abs(value).toFixed(2),
		colour: value > 0 ? 'success' : 'danger',
	};
};

export const getLastWeekEndDate = (assignedMonth) => {
	const endOfMonth = dayjs(assignedMonth).endOf('month');
	const endOfMonthDay = endOfMonth.day();

	// end of month is Sunday
	if (endOfMonthDay === 0) {
		return endOfMonth.format('YYYY-MM-DD');
	}

	const sundayOfLastWeek = endOfMonthDay <= 3 ? 0 : 7;
	return endOfMonth.day(sundayOfLastWeek).format('YYYY-MM-DD');
};
