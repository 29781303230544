<script setup>
import {
	IonPage,
	IonContent,
	IonButton,
	IonList,
	IonItem,
	IonLabel,
	loadingController,
	toastController,
	useIonRouter,
} from '@ionic/vue';
import { onMounted, ref } from 'vue';
import { getUser, supabase } from '@/utils/supabase';
import { requestNotificationPermission } from '@/utils/firebase';
import { trackEvent } from '@/utils/analytics';
import { featureFlag } from '@/utils/featureflags';
import AvatarImage from './_components/AvatarImage.vue';
import ConnectBank from './_components/ConnectBank.vue';

const router = useIonRouter();
const user = ref(null);
const notificationsButtonMsg = ref('Allow notifs');
const referralCode = ref('');

const doLogout = async () => {
	await supabase.auth.signOut();
	router.replace('/login');
};

const setNotifButtonText = () => {
	const buttonMsgs = {
		default: 'Allow',
		granted: 'Reconnect',
		denied: 'Allow',
	};

	notificationsButtonMsg.value = buttonMsgs[Notification.permission];
};

const allowNotifs = async () => {
	trackEvent('spend_settings, clicked allow/reconnect notifications');
	notificationsButtonMsg.value = 'Enabling notifications...';
	const toast = await toastController.create({ duration: 8000 });
	try {
		await requestNotificationPermission();
	} catch (err) {
		toast.message = err.message || err;
		await toast.present();
	} finally {
		setNotifButtonText();
		toast.message = 'Notifications are now enabled!';
		await toast.present();
	}
};

const copyReferralCodeToClipboard = async () => {
	const toast = await toastController.create({ duration: 8000 });
	try {
		await navigator.clipboard.writeText(referralCode.value);
		toast.message = 'Refurral code copied to clipboard <3!';
		await toast.present();
	} catch (err) {
		toast.message = err.message || err;
		await toast.present();
	}
};

const fetchReferralCode = async () => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 8000 });

	try {
		await loader.present();

		const { data } = await supabase
			.from('waitlist')
			.select('personal_code')
			.eq('user_id', user.value.id);

		if (data && data.length > 0) {
			referralCode.value = data[0].personal_code;
		}
	} catch (err) {
		toast.message = err.message || err;
		await toast.present();
	} finally {
		await loader.dismiss();
	}
};

onMounted(async () => {
	user.value = await getUser();
	setNotifButtonText();
	fetchReferralCode();
});
</script>

<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div class="content">
				<div class="container header">
					<div class="avatar-container">
						<AvatarImage />
					</div>
					<p v-if="user">
						{{ user.user_metadata?.first_name }}
					</p>
				</div>
				<div>
					<ion-item>
						<ion-label>
							Your badges
							<p>View them all!</p>
						</ion-label>
						<ion-button
							slot="end"
							@click="() => router.push('/profile/badges')">
							View
						</ion-button>
					</ion-item>
					<ion-item v-if="referralCode">
						<ion-label>
							Your referral code
							<p>
								Share your referral code with friends and
								family!
							</p>
						</ion-label>
						<ion-button
							slot="end"
							@click="copyReferralCodeToClipboard">
							Copy your code!
						</ion-button>
					</ion-item>
				</div>

				<div class="container" v-if="featureFlag('moneykit')">
					<strong>Connect Bank</strong>
					<p>
						Connect your bank account to stream your transactions
						directly into Lila. We do a lot to keep all your data
						secure, including connecting to your bank through a
						service with privacy agreements with major banks.
					</p>

					<ConnectBank />
				</div>

				<ion-list>
					<strong class="container">Settings</strong>
					<ion-item>
						<ion-label>
							Ideal goal
							<p>
								Adjust the monthly spending goal that Lila helps
								you work towards!
							</p>
						</ion-label>
						<ion-button
							slot="end"
							@click="() => router.push('/profile/ideal-goal')">
							Edit
						</ion-button>
					</ion-item>
					<ion-item>
						<ion-label>
							Notifications
							<p v-if="notificationsButtonMsg == 'Allow'">
								We want to remind you to make good financial
								decisions!!
							</p>
							<p v-else>
								Reconnect notifications if they haven't been
								working for ya
							</p>
						</ion-label>
						<ion-button slot="end" @click="allowNotifs">
							{{ notificationsButtonMsg }}
						</ion-button>
					</ion-item>
					<ion-item>
						<div>Logout</div>
						<ion-button
							slot="end"
							class="logout-button"
							@click="doLogout">
							Log out
						</ion-button>
					</ion-item>
				</ion-list>
			</div>
		</ion-content>
	</ion-page>
</template>

<style scoped>
.content {
	display: flex;
	flex-direction: column;
	gap: 50px;
	margin-top: 40px;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 0 20px;
}

.container p {
	font-size: 16px;
	line-height: 22px;
	color: #8c8c8c;
	margin: 0;
}

.container.header {
	margin: 5vh 0 2.5vh;
	align-items: center;
}

.avatar-container {
	height: 10vh;
	width: 10vh;
}
</style>
