<script setup>
import { onMounted, ref, computed } from 'vue';
import { getUser } from '@/utils/supabase';

const props = defineProps({
	active: {
		type: Boolean,
		default: false,
	},
});

const user = ref(null);
onMounted(async () => (user.value = await getUser()));

const firstInitial = computed(() => {
	return user.value?.user_metadata?.first_name?.[0]?.toUpperCase() || 'U';
});
</script>

<template>
	<div
		v-if="firstInitial"
		class="circle-avatar"
		:class="{ active: props.active }">
		{{ firstInitial }}
	</div>
</template>

<style scoped>
.circle-avatar {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #c7c9ff;
	color: black;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	border: 1px solid black;
}
.active {
	border-color: var(--ion-color-primary);
}
</style>
