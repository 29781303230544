<script setup>
import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonList,
	IonItem,
	IonLabel,
	IonChip,
} from '@ionic/vue';
import dayjs from 'dayjs';
import FloatLineChart from '@/components/FloatLineChart.vue';
import { spendDifference } from '@/utils/spendPeriods';
import { formatDate } from '@/utils/dateRange';

const props = defineProps({
	current: {
		type: Object,
		required: true,
		default: () => {},
	},
});

const isCurrentWeek = (week) =>
	dayjs().isBefore(week.end_date, 'hour') &&
	dayjs().isAfter(week.start_date, 'hour');
</script>

<template>
	<div class="deets">
		<ion-card color="light">
			<ion-card-header class="ion-text-nowrap">
				<ion-card-title>
					${{ props.current.month_goal?.toFixed(2) }}
				</ion-card-title>
			</ion-card-header>
			<ion-card-content> Month goal </ion-card-content>
		</ion-card>
		<ion-card color="light">
			<ion-card-header class="ion-text-nowrap">
				<ion-card-title>
					${{ props.current?.remaining?.toFixed(2) }}
				</ion-card-title>
			</ion-card-header>
			<ion-card-content> Remaining </ion-card-content>
		</ion-card>
	</div>
	<FloatLineChart
		v-if="props.current.dailyIncrease"
		:startDate="props.current.first_week_start"
		:endDate="formatDate(props.current.last_week_end, 'end')"
		:forecast="props.current.month_goal"
		:dailyIncrease="props.current.dailyIncrease" />
	<ion-list>
		<ion-item v-for="(period, index) in props.current.weeks" :key="index">
			<ion-label>
				<h2>
					<span v-if="isCurrentWeek(period)">Current week:</span>
					{{ dayjs(period.start_date).format('MMM D') }}
					to
					{{ dayjs(period.end_date).format('MMM D') }}
				</h2>
				<p>Forecasted: ${{ period.spending_forecast.toFixed(2) }}</p>
				<p>Spent: ${{ period.spent.toFixed(2) }}</p>
			</ion-label>
			<ion-chip
				slot="end"
				:color="
					spendDifference(period.spending_forecast, period.spent)
						.colour
				">
				{{
					spendDifference(period.spending_forecast, period.spent)
						.string
				}}
			</ion-chip>
		</ion-item>
	</ion-list>
</template>

<style scoped>
.deets {
	display: grid;
	grid-template-columns: 1fr 1fr;
	text-align: center;
}

.deets ion-card {
	box-shadow: none;
}
</style>
