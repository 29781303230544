<script setup>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
	GridComponent,
	TooltipComponent,
	MarkPointComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import VChart from 'vue-echarts';
import { onMounted, ref, computed } from 'vue';
import { getUser, supabase } from '@/utils/supabase';
import { toastController } from '@ionic/vue';
import graphOptions from './graphOptions';
import { getDayNow, formatDate, getDayDifference } from '@/utils/dateRange';
import dayjs from 'dayjs';

use([
	CanvasRenderer,
	LineChart,
	GridComponent,
	TooltipComponent,
	MarkPointComponent,
]);
const props = defineProps({
	startDate: String,
	endDate: String,
	forecast: Number,
});

const options = ref(null);

const dailyIncrease = computed(
	() => props.forecast / getDayDifference(props.startDate, props.endDate)
);

const tooltipContent = (params) => {
	const currentDate = dayjs(params[0].axisValue);
	let content = `<b>${currentDate.format('ddd h:mm a')}</b><br/>`;

	// get greenline
	const yourSpending = params.find((s) => s.seriesName == 'Spent');
	if (yourSpending) {
		const amountSpent = yourSpending.value[1].toFixed(2);
		content += `<span style="color:${yourSpending.color};">●</span> ${yourSpending.seriesName}: <b>$${amountSpent}</b><br/>`;

		// distance from line
		const daysProgressed = getDayDifference(props.startDate, currentDate);
		const idealSpent = dailyIncrease.value * daysProgressed;
		const distanceToIdeal = amountSpent - idealSpent;
		if (idealSpent > 0) {
			content += `$${Math.abs(distanceToIdeal).toFixed(2)} ${distanceToIdeal > 0 ? 'above' : 'below'} the line!<br/>`;
		}
		if (distanceToIdeal > 0 && amountSpent < props.forecast) {
			const lineDate = (amountSpent / props.forecast) * 7;
			content += `${Math.ceil(lineDate) - daysProgressed} days to the line`;
		}
	}

	// forecast
	const forecast = params.find((s) => s.seriesName == 'Forecast');
	if (forecast && dayjs(props.endDate).isSame(currentDate)) {
		content += `<span style="color:${forecast.color};">●</span> Prediction: <b>$${props.forecast}</b><br/>`;
	}

	return content;
};

const fetchTransacHistory = async (user) => {
	const toast = await toastController.create({ duration: 8000 });

	try {
		const { data } = await supabase.rpc('get_transaction_totals', {
			user_id: user.id,
			start_date: props.startDate,
			end_date: props.endDate,
		});

		let chartValues = [];
		let spent = 0;
		if (data.length) {
			chartValues = data.map((d) => [d.datetime, d.total]);
			spent = data[data.length - 1].total;
		}
		// Add current or last moment to chart data
		if (
			new Date() < new Date(props.endDate) &&
			new Date() > new Date(props.startDate)
		) {
			chartValues.push([getDayNow(), spent]);
		} else if (new Date() > new Date(props.endDate)) {
			chartValues.push([formatDate(props.endDate, 'end'), spent]);
		}

		// Add data to chart
		graphOptions.series[1].data = [[props.startDate, 0], ...chartValues];
		graphOptions.series[1].markPoint.data[0].coord =
			chartValues[chartValues.length - 1];
		graphOptions.series[0].data = [
			[props.startDate, dailyIncrease.value],
			[props.endDate, props.forecast],
		];
		graphOptions.series[0].markPoint.data[0].coord = [
			props.endDate,
			props.forecast,
		];

		// Make the chart x-axis show days of the week if it's a week-view
		if (dayjs(props.endDate).diff(dayjs(props.startDate), 'day') < 7)
			graphOptions.xAxis.axisLabel = { formatter: '               {ee}' };

		// Format the tooltip
		graphOptions.tooltip.formatter = tooltipContent;

		options.value = graphOptions;
	} catch (err) {
		toast.message = err;
		await toast.present();
	}
};

onMounted(async () => {
	const user = await getUser();
	fetchTransacHistory(user);
});
</script>

<template>
	<v-chart
		:option="options"
		:loading="!options"
		:class="{ chart: true, loading_complete: options }" />
</template>

<style scoped>
.chart {
	height: 250px;
	padding: 1rem;
}
</style>
