<script setup>
import { ref, onMounted, computed, watch, nextTick } from 'vue';
import { toastController, useIonRouter } from '@ionic/vue';
import { supabase } from '@/utils/supabase';
import dayjs from 'dayjs';

const props = defineProps({
	startDate: String,
	endDate: String,
});

const ionRouter = useIonRouter();
const transactions = ref(null);
const currentDayRef = ref(null);
const calendarRef = ref(null);

watch(transactions, async (newT) => {
	if (newT) {
		await nextTick();
		const currentDayElement = currentDayRef.value;
		const calendarElement = calendarRef.value;
		if (currentDayElement && calendarElement) {
			const scrollposition =
				currentDayElement.offsetLeft -
				currentDayElement.offsetWidth / 2;
			calendarElement.scrollTo({
				left: scrollposition,
				behavior: 'smooth',
			});
		}
	}
});

const startDateString = computed(() => dayjs(props.startDate).format('MMM D'));

const endDateString = computed(() => dayjs(props.endDate).format('MMM D'));

const setRef = (d) => {
	return (el) => {
		if (d.date === dayjs().format('YYYY-MM-DD')) {
			currentDayRef.value = el;
		}
	};
};

const getTransactions = async (session) => {
	const toast = await toastController.create({ duration: 8000 });

	try {
		const { data, error } = await supabase
			.from('transactions_w_date')
			.select('transaction_id, name, amount, datetime, date')
			.gte('datetime', props.startDate)
			.lte('datetime', props.endDate)
			.eq('user_id', session.user.id)
			.order('datetime', { ascending: true });

		if (error) {
			throw error.message;
		}

		const tmp = [];
		for (
			let currentDate = new Date(props.startDate);
			currentDate <= new Date(props.endDate);
			currentDate.setDate(currentDate.getDate() + 1)
		) {
			const dateStr = dayjs(currentDate).format('YYYY-MM-DD');
			const { itemsForDate, totalForDate } = data.reduce(
				(acc, item) => {
					if (dayjs(item.datetime).isSame(currentDate, 'day')) {
						acc.itemsForDate.push(item);
						acc.totalForDate += item.amount;
					}
					return acc;
				},
				{ itemsForDate: [], totalForDate: 0 } // Initial accumulator
			);

			tmp.push({ date: dateStr, data: itemsForDate, totalForDate });
		}

		transactions.value = tmp;
	} catch (err) {
		toast.message = err;
		await toast.present();
	}
};

onMounted(async () => {
	const { data } = await supabase.auth.getSession();
	getTransactions(data.session);
});
</script>

<template>
	<div v-if="transactions">
		<h2 class="title ion-padding-horizontal">
			Purchases from {{ startDateString }} to {{ endDateString }}
		</h2>
		<div id="calendar" ref="calendarRef">
			<ol class="week">
				<li
					v-for="d in transactions"
					:key="d"
					class="day"
					:ref="setRef(d)">
					<h3 class="date-title">
						<time
							:datetime="d.date"
							v-if="d.date === dayjs().format('YYYY-MM-DD')">
							Today!
						</time>
						<time :datetime="d.date" v-else>
							{{ dayjs(d.date).format('dddd MMM D') }}
						</time>
						<span v-if="d.totalForDate">
							${{ d.totalForDate.toFixed(2) }}
						</span>
					</h3>
					<ul>
						<li
							v-for="t in d.data"
							:key="t"
							:time="dayjs(t.datetime)"
							class="event">
							<div>
								<b>${{ t.amount }}</b>
								<ion-button
									size="small"
									@click="
										ionRouter.push(
											`/edit-transaction/${t.transaction_id}`
										)
									"
									fill="clear">
									Edit
								</ion-button>
							</div>
							<p>
								{{ t.name.toLowerCase() }}
								<time :datetime="t.datetime">
									{{ dayjs(t.datetime).format('h:mma') }}
								</time>
							</p>
						</li>
						<li
							v-if="
								dayjs().isSame(d.date, 'day') ||
								dayjs().isBefore(d.date, 'day')
							">
							<ion-button
								size="small"
								@click="
									ionRouter.push(`/add-transaction/${d.date}`)
								"
								fill="clear">
								Add
							</ion-button>
						</li>
					</ul>
				</li>
			</ol>
		</div>
	</div>
</template>

<style scoped>
.title {
	font-size: 1rem;
	font-weight: 700;
}

.date-title {
	font-size: 1rem;
	margin-top: 2px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.date-title span {
	font-size: smaller;
}

#calendar {
	overflow-x: auto; /* Enables horizontal scrolling */
	margin-bottom: 3rem;
	padding: 20px 0;
}

.week {
	display: flex;
	width: max-content; /* Ensures the flex container's width adjusts to its content */
	padding: 0;
	list-style: none;
	margin: 0;
}

.day {
	flex: none;
	width: 200px;
	margin-right: 10px;
	padding: 10px;
	box-sizing: border-box;
	flex-shrink: 0;
	background-color: #e6e0f8;
	border-radius: 15px;

	@media (prefers-color-scheme: dark) {
		background-color: #3e3148;
	}
}

.day:first-of-type {
	margin-left: 1rem;
}

.day:last-of-type {
	margin-right: 1rem;
}

.day ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

li.event {
	background-color: #c3b7f2;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 10px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	@media (prefers-color-scheme: dark) {
		background-color: #7a46a0;
	}
}

li.event div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

li.event ion-button {
	--padding-top: 0;
	--padding-bottom: 0;
}

li.event p {
	margin: 0;
	margin-bottom: 0.5rem;
}

li.event ion-button {
	align-self: end;
	margin: 0;
}
</style>
