<script setup>
import {
	IonPage,
	IonNote,
	IonInput,
	IonList,
	IonButton,
	IonCheckbox,
	IonItem,
	loadingController,
	toastController,
	useIonRouter,
} from '@ionic/vue';
import { reactive, unref, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import {
	containsLowercase,
	containsNumber,
	containsSpecial,
	containsUppercase,
	validationMessages,
} from '@/utils/validations.js';
import { supabase } from '@/utils/supabase';
import { identifyUser, trackUser, trackEvent } from '@/utils/analytics';

const router = useIonRouter();

const state = reactive({
	first_name: '',
	email: '',
	password: '',
	agreeTerms: false,
	referrer: '',
	referral_code: null,
});

const rules = computed(() => ({
	first_name: { required },
	email: { required, email },
	password: {
		required,
		minLength: minLength(6),
		containsUppercase,
		containsLowercase,
		containsNumber,
		containsSpecial,
	},
	agreeTerms: { required },
}));

const v$ = useVuelidate(rules, state, { $lazy: true });

const checkReferralCode = async () => {
	if (!state.referral_code) return;
	fetchReferralData(state.referral_code);
};

const onSubmit = async () => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 8000 });

	try {
		await loader.present();
		const isValid = await unref(v$).$validate();
		if (!isValid || !state.agreeTerms) {
			throw {
				message: 'Somethings not filled in right, please meow again!',
				form_is_valid: isValid,
				agreed_to_terms: state.agreeTerms,
			};
		}

		const { data, error } = await supabase.auth.signUp({
			email: state.email,
			password: state.password,
		});
		if (error) throw error;

		// Upsert data into the 'waitlist' table
		const { error: error1 } = await supabase.from('waitlist').upsert({
			user_id: data.user.id,
			name: state.first_name,
			referral_code: state.referral_code,
		});
		if (error1) throw error1;

		identifyUser(data.session);
		trackUser(
			{
				...data.session,
				referral: {
					referrer: state.referrer,
					referral_code: state.referral_code,
				},
			},
			'SIGN_UP'
		);

		await router.push('/onelaststep');
	} catch (err) {
		trackEvent('Sign up error', err);
		toast.message = err.message || err;
		await toast.present();
	} finally {
		loader.dismiss();
	}
};

const fetchReferralData = async (referral_code) => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 8000 });

	try {
		await loader.present();

		const { data, error } = await supabase
			.from('waitlist_placement_view')
			.select('name')
			.eq('personal_code', referral_code);
		if (error) throw error;
		if (!data.length) throw { detail: 'No user with this referral_code' };

		state.referrer = data[0].name;
		state.referral_code = referral_code;
	} catch (error) {
		trackEvent('Invalid referral', {
			attempted_code: referral_code,
			...error,
		});
		toast.message = `I'm not sure who that is! It's giving suss`;
		await toast.present();
	} finally {
		loader.dismiss();
	}
};
</script>

<template>
	<ion-page class="signup-page">
		<form @submit.prevent="onSubmit" autocomplete="on">
			<div class="top">
				<h1 v-if="!state.referrer">Meet Lila</h1>
				<h1 v-if="state.referrer">
					{{ state.referrer }} wants you to meet Lila
				</h1>
				<img alt="Lila the cat" src="/badge-lila.png" />
				<p>
					Join our waitlist for early access to the app, and exclusive
					bts content on social media.
				</p>
			</div>
			<ion-list>
				<ion-item lines="none">
					<ion-input
						color="dark"
						required="true"
						v-model="state.first_name"
						name="firstName"
						autocomplete="given-name"
						label="First Name"
						placeholder="What's yours?">
					</ion-input>
				</ion-item>

				<ion-item lines="none">
					<ion-input
						color="dark"
						required="true"
						v-model="state.email"
						name="email"
						autocomplete="email"
						label="Email"
						type="email"
						placeholder="No spam! I promise">
					</ion-input>
				</ion-item>

				<ion-item lines="none">
					<ion-input
						color="dark"
						required="true"
						label="Password"
						type="password"
						v-model="state.password"
						name="password"
						autocomplete="new-password"
						placeholder="For purr-ivacy"
						@ionBlur="v$.password.$touch">
					</ion-input>
				</ion-item>

				<div v-if="v$.password.$error">
					<ion-note>
						Weak Password
						<ul>
							<li
								v-for="error in v$.password.$errors"
								:key="error">
								{{ validationMessages[error.$validator] }}
							</li>
						</ul>
					</ion-note>
				</div>

				<ion-item lines="none">
					<ion-input
						color="dark"
						class="referral_code"
						v-model="state.referral_code"
						name="referral_code"
						autocomplete="off"
						label="Referral Code"
						v-on:ion-blur="checkReferralCode()"
						placeholder="(faster access)">
					</ion-input>
				</ion-item>

				<ion-item lines="none" class="checkbox">
					<ion-checkbox
						slot="start"
						v-model="state.agreeTerms"
						label-placement="end">
						<div class="ion-text-wrap" style="font-size: 15px">
							Agree to our
							<a
								href="https://www.notion.so/arjunkalburgi/Terms-of-Service-9013b99883f44800ae7935ccb7c5c0ed?pvs=4"
								target="_blank">
								Terms of Service
							</a>
							and
							<a
								href="https://www.notion.so/arjunkalburgi/Privacy-Policy-ae2989653eb547dd9f46fbd7152d77f5?pvs=4"
								target="_blank">
								Privacy Policy
							</a>
							to continue.
						</div>
					</ion-checkbox>
				</ion-item>

				<ion-button class="signup-btn" type="submit" shape="round">
					Continue
				</ion-button>
			</ion-list>
		</form>
	</ion-page>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap');

.signup-page {
	--ion-color-primary: #b084f1;
	--ion-color-primary-rgb: 176, 132, 241;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #9b74d4;
	--ion-color-primary-tint: #b890f2;
	--ion-color-secondary: #e3fffa;
	--ion-color-secondary-rgb: 227, 255, 250;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #c8e0dc;
	--ion-color-secondary-tint: #e6fffb;
	--ion-color-tertiary: #ff52af;
	--ion-color-tertiary-rgb: 255, 82, 175;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #e0489a;
	--ion-color-tertiary-tint: #ff63b7;
	--ion-color-success: #e3fffa;
	--ion-color-success-rgb: 227, 255, 250;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #c8e0dc;
	--ion-color-success-tint: #e6fffb;
	overflow: scroll;
}

h1 {
	font-family: 'Judson', serif;
	font-weight: 700 !important;
	font-size: 40px !important;
	line-height: 100%;
	@media (max-width: 670px) {
		font-size: 40px;
	}
}

.top {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

form {
	width: calc((100% + (2 * 70px)) / 2);
	min-width: 300px;
	margin: auto;

	@media (max-width: 568px) {
		min-width: none;
	}
}

.top img {
	width: 200px; /* Adjust size as needed */
	height: 200px; /* Ensure height matches width for a circle */
}
.image-container img {
	object-fit: cover;
}
.text-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.signup-page ion-item {
	--background: transparent;
	overflow: visible;
	width: 100%;
}
.signup-page ion-input {
	--background: #fff;
	--color: #000;
	--placeholder-color: #565656;
	--placeholder-opacity: 1;
	--border-radius: 15px;
	--border-color: #000;
	--border-style: solid;
	--border-width: 2px;
	--box-shadow: 4px 4px;
	--padding-start: 20px;
	--padding-end: 20px;
	--padding-top: 4px;
	--padding-bottom: 4px;
	--placeholder-font-weight: 500;

	--color: #000;
	caret-color: black;

	--placeholder-color: #1a1a1a;
	--placeholder-opacity: 1;
	--placeholder-font-weight: 400;

	--border-radius: 15px;
	border-radius: 17px;
	--border-color: #000;
	--border-style: solid;
	--border-width: 2px;
	border: 2px solid black;

	--box-shadow: 4px 4px;
	box-shadow: 4px 4px;

	--padding-start: 20px;
	--padding-end: 20px;
	--padding-top: 11px;
	--padding-bottom: 11px;

	--highlight-height: 0px;
	margin-bottom: 15px;
	font-weight: 700;

	font-family: 'Ubuntu';
	font-weight: 700;
	--placeholder-font-weight: 500;

	max-width: 300px;
	margin-left: auto;
	margin-right: auto;
}
.signup-page ion-list {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0;
	background-color: transparent;
}
.signup-page ion-item.checkbox {
	/* width: 85%; */
	margin-bottom: 20px;
}

ion-item.checkbox ion-checkbox {
	--background: white;
	--border-color: black;
}

.password-div {
	width: 40vh;
}

.center-button-item {
	display: flex;
	justify-content: center;
	align-items: center;
}

.signup-page ion-button {
	--background: #d61d80;
	--background-hover: #c7237a;
	--background-activated: #c7237a;
	--background-focused: #c7237a;

	--color: white;

	--border-radius: 15px;
	--border-color: #000;
	--border-style: solid;
	--border-width: 2px;

	--box-shadow: 4px 4px black;

	--padding-top: 10px;
	--padding-bottom: 10px;

	text-transform: none;

	font-family: 'Ubuntu';
	font-weight: 700 !important;
}
</style>
