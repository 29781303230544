<script setup>
import {
	IonAccordionGroup,
	IonAccordion,
	IonList,
	IonItem,
	IonLabel,
	IonChip,
} from '@ionic/vue';
import dayjs from 'dayjs';
import { spendDifference } from '@/utils/spendPeriods';

const props = defineProps({
	months: {
		type: Array,
		required: true,
		default: () => [],
	},
});
</script>

<template>
	<ion-accordion-group expand="inset">
		<ion-accordion
			v-for="(month, index) in props.months"
			:key="index"
			:value="index">
			<ion-item slot="header" color="light">
				<ion-label>
					<h2>
						{{ dayjs(month.assigned_month).format('MMM YYYY') }}
					</h2>
					<p>Month goal: ${{ month.month_goal.toFixed(2) }}</p>
					<p>Total spent: ${{ month.total_spent.toFixed(2) }}</p>
				</ion-label>
				<ion-chip
					:color="
						spendDifference(month.month_goal, month.total_spent)
							.colour
					">
					{{
						spendDifference(month.month_goal, month.total_spent)
							.string
					}}
				</ion-chip>
			</ion-item>
			<div class="ion-padding" slot="content">
				<ion-list>
					<ion-item
						v-for="(period, index) in month.weeks"
						:key="index">
						<ion-label>
							<h2>
								{{ dayjs(period.start_date).format('MMM DD') }}
								to
								{{ dayjs(period.end_date).format('MMM DD') }}
							</h2>
							<p>
								Forecasted: ${{
									period.spending_forecast.toFixed(2)
								}}
							</p>
							<p>Spent: ${{ period.spent.toFixed(2) }}</p>
						</ion-label>
						<ion-chip
							slot="end"
							:color="
								spendDifference(
									period.spending_forecast,
									period.spent
								).colour
							">
							{{
								spendDifference(
									period.spending_forecast,
									period.spent
								).string
							}}
						</ion-chip>
					</ion-item>
				</ion-list>
			</div>
		</ion-accordion>
	</ion-accordion-group>
</template>
