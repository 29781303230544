<script setup>
import { ref, computed } from 'vue';
import { IonItem, IonRange, IonButton, IonImg } from '@ionic/vue';
import FloatLineChart from '@/components/FloatLineChart.vue';
import dayjs from 'dayjs';
import { trackEvent } from '@/utils/analytics';

const props = defineProps({
	endingPeriod: Object,
	chartData: Object,
});

const emit = defineEmits(['next-step']);
const fulfillment = ref(0);
const lastWeekDistanceToIdeal = computed(
	() => props.endingPeriod?.spending_forecast - props.endingPeriod?.spent
);
const lastWeekSpentLess = computed(() => lastWeekDistanceToIdeal.value > 0);

const submitRewarding = () => {
	trackEvent('Rewarding experience', {
		fulfillment: fulfillment.value,
	});

	trackEvent('Spending week results', {
		weekStartDate: props.endingPeriod?.startDate,
		success: lastWeekSpentLess.value,
		successAmount: lastWeekDistanceToIdeal.value,
	});

	emit('next-step');
};
</script>

<template>
	<div v-if="endingPeriod">
		<div class="container">
			<ion-img
				v-if="lastWeekSpentLess"
				class="big"
				src="assets/cat/trophy_big.png"
				alt="Lila sitting in front of a trophy twice the size of her!" />
			<ion-img
				v-else
				class="small"
				src="assets/cat/trophy_small.png"
				alt="Lila holding a small participation ribbon" />

			<p class="number-highlight">
				${{ Math.abs(lastWeekDistanceToIdeal).toFixed(0) }}
				{{ lastWeekSpentLess ? 'under!!' : 'over' }}
			</p>

			<p class="number-followup">
				{{
					lastWeekSpentLess
						? 'You are a purrfect kitty, MEOW 🥳'
						: 'Sharpen those claws and try again'
				}}
			</p>

			<p>
				{{ endingPeriod ? 'This' : 'Last' }} week, Lila predicted you'd
				spend ${{ endingPeriod.spending_forecast?.toFixed(2) }}, and you
				spent ${{ endingPeriod.spent }}.
			</p>
		</div>
		<div class="container">
			<strong class="title">An overview of your recent past...</strong>

			<p v-if="chartData">
				Your spending from
				{{ dayjs(chartData.startDate).format('MMM D') }} to
				{{ dayjs(chartData.endDate).format('MMM D') }}
			</p>

			<FloatLineChart
				v-if="chartData"
				:startDate="chartData.startDate"
				:endDate="chartData.endDate"
				:forecast="chartData.forecast"
				:dailyIncrease="0" />
		</div>
		<div class="container">
			<form @submit.prevent="submitRewarding">
				<ion-item
					class="ion-margin-vertical ion-no-padding"
					lines="none">
					<ion-range
						label-placement="stacked"
						label="How rewarding is this experience?"
						:ticks="true"
						:snaps="true"
						:min="-3"
						:max="3"
						required="true"
						name="rewarding"
						v-model="fulfillment">
						<span slot="start">😾</span>
						<span slot="end">😺</span>
					</ion-range>
				</ion-item>
				<div>
					<ion-button
						class="next-button"
						expand="block"
						color="success"
						type="submit">
						Next
					</ion-button>
				</div>
			</form>
		</div>
	</div>
</template>

<style scoped>
.container {
	padding-top: 1rem;
}

ion-img {
	margin: auto;
	margin-top: 2rem;
}

ion-img.big {
	width: 70vw;
	max-width: 400px;
}

ion-img.small {
	width: 40vw;
	max-width: 300px;
}

.title {
	font-size: 1.2rem;
}

.number-highlight {
	font-size: 2rem;
	font-weight: bold;
	text-align: center;
	margin-top: 1rem;
	margin-bottom: 0;
}

.number-followup {
	text-align: center;
	margin-bottom: 2rem;
}
</style>
