<template>
	<ion-page>
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button @click="this.$router.push({ path: '/' })">
						<ion-icon :icon="arrowBackOutline"></ion-icon>
					</ion-button>
				</ion-buttons>
				<ion-title>Add Transaction</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true">
			<div class="content">
				<div class="container">
					<strong>Enter transaction manually</strong>
					<p>Fill out the details below and hit the save button</p>
					<ion-item>
						<ion-label>Name</ion-label>
						<ion-input v-model="transac.name"></ion-input>
					</ion-item>
					<ion-item>
						<ion-label>Date</ion-label>
						<ion-datetime-button
							datetime="datetime"></ion-datetime-button>
					</ion-item>
					<ion-item>
						<ion-label>Amount</ion-label>
						<ion-input
							type="number"
							v-model="transac.amount"></ion-input>
					</ion-item>
					<p
						v-if="
							v$.transac.amount.$invalid && transac.amount != null
						"
						class="error-text">
						{{
							'Please enter a positive number with upto 2 decimal places, eg: 123, 12.23, 20, 2'
						}}
					</p>
					<ion-buttons slot="end">
						<ion-button
							fill="solid"
							@click="saveTransac"
							:disabled="
								transac.amount === null ||
								v$.transac.datetime.$invalid ||
								v$.transac.amount.$invalid
							">
							Save
						</ion-button>
					</ion-buttons>
				</div>
			</div>
			<ion-modal :keep-contents-mounted="true">
				<ion-datetime
					id="datetime"
					presentation="time-date"
					v-model="transac.datetime"></ion-datetime>
			</ion-modal>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonItem,
	IonLabel,
	IonInput,
	IonButton,
	IonButtons,
	IonIcon,
	IonDatetime,
	IonDatetimeButton,
	IonModal,
} from '@ionic/vue';
import { arrowBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { formatDate, formatDateLocalISO } from '@/utils/dateRange';
import { containsPositiveFloat } from '@/utils/validations';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { setTransactions } from '../utils/transactions.js';
import { trackEvent } from '@/utils/analytics';

export default defineComponent({
	name: 'HomePage',
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonLabel,
		IonButton,
		IonButtons,
		IonInput,
		IonIcon,
		IonDatetime,
		IonDatetimeButton,
		IonModal,
	},
	setup() {
		return { arrowBackOutline, v$: useVuelidate() };
	},
	created() {
		if (this.$route.params.date) {
			const [year, month, day] = this.$route.params.date
				.split('-')
				.map(Number);
			const date = new Date(this.transac.datetime);
			date.setFullYear(year, month - 1, day);
			// ion-datetime requires ISO
			this.transac.datetime = formatDateLocalISO(date);
		}
	},
	data() {
		return {
			transac: {
				transaction_id: Math.random(),
				name: 'Store',
				datetime: formatDate(),
				amount: null,
			},
			dollar: Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
			}),
		};
	},
	validations: {
		transac: {
			datetime: {
				required,
			},
			amount: {
				required,
				containsPositiveFloat,
			},
		},
	},
	methods: {
		saveTransac() {
			let newTransactions = [];

			if (this.transac.amount !== null) {
				let t = {
					...this.transac,
					amount: this.transac.amount * 1,
					datetime: formatDate(this.transac.datetime),
				};
				newTransactions.push(t);
			}

			setTransactions(newTransactions).then(() => {
				trackEvent('Transaction Added');
				this.$router.push({ path: '/' });
				this.$router.go();
			});
		},
	},
});
</script>

<style scoped>
.content {
	display: flex;
	flex-direction: column;
	gap: 50px;
	margin-top: 40px;
}

.content > .container > div > :not(ion-item, ion-list),
.content > .container > strong,
.content > .container > p,
.content > .container > ion-buttons {
	padding: 0 20px;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.container p {
	font-size: 16px;
	line-height: 22px;
	color: #8c8c8c;
	margin: 0;
}

.container a {
	text-decoration: none;
}

ion-input {
	text-align: end;
}

ion-buttons {
	padding: 20px 0;
	gap: 20px;
}

ion-button {
	width: 100%;
}

.container p.error-text {
	font-size: x-small;
	color: #ab3030;
	--min-height: 16px;
}

@media screen and (min-width: 500px) {
	.container {
		max-width: 30rem;
		margin: auto;
		min-width: 25.88rem;
	}
}
</style>
