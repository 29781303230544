<script setup>
import {
	IonPage,
	IonButton,
	IonSelect,
	IonSelectOption,
	loadingController,
	toastController,
	useIonRouter,
} from '@ionic/vue';
import { reactive, unref, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { supabase } from '@/utils/supabase';
import { trackEvent } from '@/utils/analytics';

const router = useIonRouter();

const state = reactive({
	age: null,
	current_budgeting: null,
	income: null,
});

const rules = computed(() => ({
	age: { required },
	current_budgeting: { required },
	income: { required },
}));
const v$ = useVuelidate(rules, state, { $lazy: true });

const onSubmit = async () => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 8000 });
	const {
		data: { session },
	} = await supabase.auth.getSession();

	try {
		await loader.present();
		const isValid = await unref(v$).$validate();
		if (!isValid)
			throw {
				message: 'Somethings not filled in right, please meow again!',
				form_is_valid: isValid,
			};

		// Upsert data into the 'waitlist' table
		const { error } = await supabase.from('waitlist').upsert({
			user_id: session.user.id,
			age: state.age,
			current_budgeting: state.current_budgeting,
			income_situation: state.income,
		});
		if (error) throw error;

		trackEvent('Additional questions answered', {
			...state,
		});

		await router.push('/#/countdown-to-app');
	} catch (error) {
		trackEvent('Additional questions error', { error });
		toast.message = error.message || error;
		await toast.present();
	} finally {
		loader.dismiss();
	}
};
</script>

<template>
	<ion-page class="onelaststep">
		<form @submit.prevent="onSubmit">
			<h1>A few more questions...</h1>
			<div>
				<p>
					Lila is for everyone! But she'd love to get to know a little
					bit about you so she can figure out how best to help
				</p>

				<ion-select
					label-placement="stacked"
					label="How old are you?"
					placeholder="I am..."
					v-model="state.age">
					<ion-select-option>>18</ion-select-option>
					<ion-select-option>18-21</ion-select-option>
					<ion-select-option>22-25</ion-select-option>
					<ion-select-option>26-30</ion-select-option>
					<ion-select-option>31-35</ion-select-option>
					<ion-select-option>36+</ion-select-option>
				</ion-select>

				<ion-select
					label-placement="stacked"
					label="How's managing your spending going?"
					placeholder="I feel..."
					v-model="state.current_budgeting">
					<ion-select-option>
						I don't manage it at all 😭
					</ion-select-option>
					<ion-select-option>
						I feel very stressed and overwhelmed. 😾
					</ion-select-option>
					<ion-select-option>
						I have some stress but try to manage it! 😽
					</ion-select-option>
					<ion-select-option>
						I manage but it sucks :/
					</ion-select-option>
					<ion-select-option>
						I feel confident and in control. $_$
					</ion-select-option>
				</ion-select>

				<ion-select
					label-placement="stacked"
					label="What's your income situation?"
					placeholder="It is..."
					v-model="state.income">
					<ion-select-option>
						I am currently unemployed.
					</ion-select-option>
					<ion-select-option>
						My expenses goes way over my income.
					</ion-select-option>
					<ion-select-option>
						My expenses are about equal to my income.
					</ion-select-option>
					<ion-select-option>
						My income more than covers my expenses.
					</ion-select-option>
				</ion-select>
			</div>

			<div>
				<ion-button class="signup-btn" type="submit" shape="round">
					Finish
				</ion-button>
			</div>
		</form>
	</ion-page>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap');

.onelaststep {
	--ion-color-primary: #b084f1;
	--ion-color-primary-rgb: 176, 132, 241;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #9b74d4;
	--ion-color-primary-tint: #b890f2;
	--ion-color-secondary: #e3fffa;
	--ion-color-secondary-rgb: 227, 255, 250;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #c8e0dc;
	--ion-color-secondary-tint: #e6fffb;
	--ion-color-tertiary: #ff52af;
	--ion-color-tertiary-rgb: 255, 82, 175;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #e0489a;
	--ion-color-tertiary-tint: #ff63b7;
	--ion-color-success: #e3fffa;
	--ion-color-success-rgb: 227, 255, 250;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #c8e0dc;
	--ion-color-success-tint: #e6fffb;
	overflow: scroll;
	align-items: center;
	justify-content: center;
}

h1 {
	font-family: 'Judson', serif;
	font-weight: 700 !important;
	font-size: 40px !important;
	line-height: 100%;
	@media (max-width: 670px) {
		font-size: 40px;
	}
}

form {
	width: calc((100% + (2 * 70px)) / 2);
	min-width: 300px;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
ion-item {
	--background: transparent;
	overflow: visible;
	width: 100%;
}

.onelaststep ion-button {
	--background: #d61d80;
	--background-hover: #c7237a;
	--background-activated: #c7237a;
	--background-focused: #c7237a;

	--color: white;

	--border-radius: 15px;
	--border-color: #000;
	--border-style: solid;
	--border-width: 2px;

	--box-shadow: 4px 4px black;

	--padding-top: 10px;
	--padding-bottom: 10px;

	text-transform: none;

	font-family: 'Ubuntu';
	font-weight: 700 !important;
}
</style>
