<script setup>
import { ref, onMounted } from 'vue';
import {
	IonLabel,
	IonItem,
	IonButton,
	IonRadio,
	IonRadioGroup,
	modalController,
} from '@ionic/vue';
import dayjs from 'dayjs';
import FloatLineChart from '@/components/FloatLineChart.vue';
import { trackEvent } from '@/utils/analytics';
import confetti from 'canvas-confetti';

defineProps({
	endingPeriod: Object,
	nextSpendingPeriod: Object,
	chartData: Object,
});
const satisfaction = ref(null);

const submitPredictionSatisfaction = () => {
	trackEvent('Prediction satisfaction', {
		satisfaction: satisfaction.value,
	});

	modalController.dismiss();
	window.location.reload();
};

const blowConfetti = () => {
	const myConfetti = confetti.create(undefined, { resize: true });
	myConfetti({
		particleCount: 100,
		startVelocity: 30,
		spread: 360,
		origin: {
			x: 0.5,
			y: 0.2,
		},
	});
};

onMounted(() => {
	// No confetti for Percy - Percy env var is added to preview links on Vercel
	if (!process.env.VUE_APP_IS_PERCY) blowConfetti();
});
</script>

<template>
	<div class="container">
		<strong class="title">
			{{ endingPeriod ? 'Next' : 'This' }} week...
		</strong>
		<p>
			After some major considerations, Lila has predicted that you will
			spend:
		</p>

		<p class="number-highlight" v-if="nextSpendingPeriod">
			${{ nextSpendingPeriod.spending_forecast }}
		</p>

		<p>Congratulations on another week! You can do it!</p>
	</div>
	<div class="container">
		<strong class="title" v-if="chartData">
			A look from
			{{ dayjs(chartData.startDate).format('MMM D') }} to
			{{ dayjs(chartData.endDate).format('MMM D') }}
		</strong>

		<FloatLineChart
			v-if="chartData"
			:startDate="chartData.startDate"
			:endDate="chartData.endDate"
			:forecast="chartData.forecast"
			:dailyIncrease="0" />

		<form @submit.prevent="submitPredictionSatisfaction">
			<ion-label position="stacked" class="ion-text-wrap title">
				Does this forecast seem right to you?
			</ion-label>
			<ion-radio-group
				v-model="satisfaction"
				required
				class="ion-margin-top">
				<ion-item>
					<ion-radio value="-1" justify="space-between">
						📉 It's too low
					</ion-radio>
				</ion-item>
				<ion-item>
					<ion-radio value="0" justify="space-between">
						✅ It looks right
					</ion-radio>
				</ion-item>
				<ion-item>
					<ion-radio value="1" justify="space-between">
						📈 It's too high
					</ion-radio>
				</ion-item>
			</ion-radio-group>

			<div>
				<ion-button expand="block" color="success" type="submit">
					Done
				</ion-button>
			</div>
		</form>
	</div>
</template>

<style scoped>
.container {
	padding-top: 1rem;
}

.title {
	font-size: 1.2rem;
}

.ion-text-wrap {
	overflow: visible;
	font-size: 1rem;
}

.number-highlight {
	font-size: 2rem;
	font-weight: bold;
	text-align: center;
}
</style>
