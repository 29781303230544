<script setup>
import {
	IonContent,
	IonPage,
	loadingController,
	toastController,
} from '@ionic/vue';
import { onMounted, ref } from 'vue';
import { getUser, supabase } from '@/utils/supabase';
import dayjs from 'dayjs';
import { trackEvent } from '@/utils/analytics';
import { getLastWeekEndDate } from '@/utils/spendPeriods';
import { getDayDifference, getWeekDifference } from '@/utils/dateRange';
import CurrentMonth from './_components/CurrentMonth.vue';
import PastSpends from './_components/PastSpends.vue';

const current = ref({});
const periods = ref([]);
const userEmail = ref();

const fetchSpendHistory = async (user) => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 8000 });

	try {
		await loader.present();
		const { data, error } = await supabase.rpc(
			'get_spend_history_with_months',
			{
				user_id_input: user.id,
			}
		);

		if (error) throw error.message;

		current.value = data.splice(0, 1)[0];
		current.value.last_week_end = getLastWeekEndDate(
			current.value.assigned_month
		);
		/**
		 * Setting the month goal as the:
		 * SUM_EXISTING_WEEKS + REMAINING_WEEKS * CURRENT_WEEK_GOAL
		 * Math.ceil includes the current week
		 */
		const REMAINING_WEEKS = Math.ceil(
			getWeekDifference(
				current.value.last_week_end,
				current.value.weeks[0].end_date
			)
		);
		current.value.month_goal =
			current.value.month_goal +
			REMAINING_WEEKS * current.value.avg_weekly_target;
		current.value.remaining =
			current.value.month_goal - current.value.total_spent;
		current.value.dailyIncrease =
			current.value.month_goal /
			getDayDifference(
				current.value.first_week_start,
				current.value.last_week_end
			);

		periods.value = data;
	} catch (err) {
		trackEvent('Error loading SpendHistory', err);
		toast.message = err;
		await toast.present();
	} finally {
		loader.dismiss();
	}
};

onMounted(async () => {
	const user = await getUser();
	userEmail.value = user.email;
	fetchSpendHistory(user);
});
</script>

<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div class="content">
				<div class="container" v-if="current">
					<h1>
						{{ dayjs(current.assigned_month).format('MMMM YYYY') }}
					</h1>
					<CurrentMonth :current="current" />
				</div>
				<div class="container" v-if="periods.length">
					<h1>Past Months</h1>
					<PastSpends :months="periods" />
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<style scoped>
.content {
	display: flex;
	flex-direction: column;
	gap: 50px;
	margin-top: 40px;
}

.content > .container > div > :not(ion-item, ion-list),
.content > .container > h1,
.content > .container > p,
.content > .container > ion-buttons {
	padding: 0 20px;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.container p {
	font-size: 16px;
	line-height: 22px;

	color: #8c8c8c;

	margin: 0;
}

ion-input {
	text-align: right;
}
</style>
