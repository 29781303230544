/**
 * Functions to interface with date range :D
 * This is used on the graph visualization, as well as to filter transactions.
 *
 * Currently, we only support monthly range. But we'll want to support custom ranges one day.
 */

import dayjs from 'dayjs';
export const supabasedatetime = 'YYYY-MM-DD HH:mm:ssZ';

export const formatDateLocalISO = (date) =>
	dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ');

export const formatDate = (date = undefined, when = '') => {
	if (when === 'start') {
		return dayjs(date).startOf('day').format(supabasedatetime);
	} else if (when === 'end') {
		return dayjs(date).endOf('day').format(supabasedatetime);
	}
	return dayjs(date).format(supabasedatetime);
};

export const createFirstDay = () => {
	// return the start of the Monday of the current week
	// Sunday (day 0) will return the next day (Monday, day 1)
	return dayjs().day(1).startOf('day').format(supabasedatetime);
};

export const createLastDay = () => {
	// return the end of the Sunday of the current week
	// Sunday (day 0) will return the next Sunday (day 7)
	return dayjs().day(7).endOf('day').format(supabasedatetime);
};

/**
 * This is used for the SpendWatching Chart
 * @param {string} curr_date: "YYYY-MM-DD"
 * @param {string} start_date: "YYYY-MM-DD"
 * @param {string} end_date: "YYYY-MM-DD"
 * @returns { number } "0.xx"
 */
export const percentageOfDatePeriod = (curr_date, start_date, end_date) => {
	var start = new Date(start_date),
		end = new Date(end_date),
		now = new Date(curr_date);
	return (now - start) / (end - start);
};

export const getWeekDifference = (endDate, startDate = dayjs()) => {
	return dayjs(endDate).diff(startDate, 'week', true).toFixed(2);
};

/**
 * Gets the difference in days between 2 dates
 * @param {string | number} startDate: "date string or milliseconds"
 * @param {string | number} end_date: "date string or milliseconds"
 * @returns {	string } "14"
 */
export const getDayDifference = (startDate, endDate) => {
	return Math.ceil(dayjs(endDate).diff(startDate, 'day', true));
};

/**
 * Gets the date now formatted for Postgres Data fetching
 * @returns {	string } "2024-01-01 11:40:14+09"
 */
export const getDayNow = () => {
	return dayjs().format(supabasedatetime);
};
