const badgeData = {
	WeekGoal: {
		name: 'Week Goal',
		description:
			'You earn this badge every week you spend less than our week prediction!',
		image: 'assets/badges/WeekGoal.png',
	},
	MonthGoal: {
		name: 'Month Goal',
		description:
			'You earn this badge every month you spend less than our month prediction!',
		image: 'assets/badges/MonthGoal.png',
	},
	WeekStreakTwo: {
		name: 'Two weeks in a row',
		description:
			'You earn this badge when you have spend less than our week prediction 2 weeks in a row.',
		image: 'assets/badges/WeekStreakTwo.png',
	},
	WeekStreakThree: {
		name: 'Three weeks in a row',
		description:
			'You earn this badge when you have spend less than our week prediction 3 weeks in a row.',
		image: 'assets/badges/WeekStreakThree.png',
	},
	WeekStreakFour: {
		name: 'Four weeks in a row',
		description:
			'You earn this badge when you have spend less than our week prediction 4 weeks in a row.',
		image: 'assets/badges/WeekStreakFour.png',
	},
	WeekStreakFive: {
		name: 'Five weeks in a row',
		description:
			'You earn this badge when you have spend less than our week prediction 5 weeks in a row.',
		image: 'assets/badges/WeekStreakFive.png',
	},
	WeekStreakSix: {
		name: 'Six weeks in a row',
		description:
			'You earn this badge when you have spend less than our week prediction 6 weeks in a row.',
		image: 'assets/badges/WeekStreakSix.png',
	},
	WeekStreakSeven: {
		name: 'Seven weeks in a row',
		description:
			'You earn this badge when you have spend less than our week prediction 7 weeks in a row.',
		image: 'assets/badges/WeekStreakSeven.png',
	},
	WeekStreakEight: {
		name: 'Eight weeks in a row',
		description:
			'You earn this badge when you have spend less than our week prediction 8 weeks in a row.',
		image: 'assets/badges/WeekStreakEight.png',
	},
	WeekStreakNine: {
		name: 'Nine weeks in a row',
		description:
			'You earn this badge when you have spend less than our week prediction 9 weeks in a row.',
		image: 'assets/badges/WeekStreakNine.png',
	},
	WeekStreakTen: {
		name: 'Ten weeks in a row',
		description:
			'You earn this badge when you have spend less than our week prediction 10 weeks in a row.',
		image: 'assets/badges/WeekStreakTen.png',
	},
	WeekStreakEleven: {
		name: 'Eleven weeks in a row',
		description:
			'You earn this badge when you have spend less than our week prediction 11 weeks in a row.',
		image: 'assets/badges/WeekStreakEleven.png',
	},
	WeekStreakTwelve: {
		name: 'Twelve weeks in a row',
		description:
			'You earn this badge when you have spend less than our week prediction 12 weeks in a row.',
		image: 'assets/badges/WeekStreakTwelve.png',
	},
	WeekStreakThirteen: {
		name: 'Thirteen weeks in a row',
		description:
			'You earn this badge when you have spend less than our week prediction 13 weeks in a row.',
		image: 'assets/badges/WeekStreakThirteen.png',
	},
};

export default badgeData;
