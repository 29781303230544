import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import { getUser, getUserDetails } from '@/utils/supabase';
import { trackPageView } from '@/utils/analytics';
import JustRouter from '@/router/JustRouter.vue';

import LoginPage from './auth/LoginPage.vue';
import MagicLink from './auth/MagicLink.vue';
import SignUpPage from './auth/signup/SignUpPage.vue';
import AdditionalQs from './auth/signup/AdditionalQs.vue';
import ResetPasswordPage from '../views/onboarding/ResetPassword.vue';
import CountdownPage from './countdown/CountdownPage.vue';

import TabsNav from './main/TabsNav.vue';
import MainPage from './main/MainPage.vue';
import ProfilePage from './main/profile/ProfilePage.vue';
import SpendSettings from './main/spendsettings/SpendSettings.vue';

import AddTransaction from '../views/AddTransaction.vue';
import EditTransaction from '../views/EditTransaction.vue';

import IdealGoal from './main/profile/ideal-goal/IdealGoal.vue';
import AllBadges from './main/profile/badges/AllBadges.vue';
import MoneyKitOauth from './main/profile/_components/MoneyKitOauth.vue';

export const routes = [
	{
		path: '/',
		component: TabsNav,
		children: [
			{
				path: '/',
				name: 'home',
				component: MainPage,
				meta: { requiresAuth: true },
				beforeEnter: async (to, from, next) => {
					if ((await getUserDetails())?.isActiveUser) {
						next();
					}
					next('/countdown-to-app');
				},
			},
			{
				path: '/spend-settings',
				name: 'spendsettings',
				meta: { requiresAuth: true },
				component: SpendSettings,
			},
			{
				path: '/profile/',
				component: JustRouter,
				children: [
					{
						path: '',
						name: 'profile',
						component: ProfilePage,
						meta: { requiresAuth: true },
					},
					{
						path: 'badges',
						name: 'Badges',
						component: AllBadges,
						meta: { requiresAuth: true },
					},
					{
						path: 'ideal-goal',
						name: 'IdealGoal',
						component: IdealGoal,
						meta: { requiresAuth: true },
					},
				],
			},
		],
	},
	{
		path: '/countdown-to-app',
		name: 'CountdownPage',
		component: CountdownPage,
		meta: { requiresAuth: true },
		beforeEnter: async (to, from, next) => {
			if ((await getUserDetails())?.isActiveUser) {
				next('/');
			}
			next();
		},
	},
	{
		path: '/edit-transaction/:id?',
		name: 'Edit Transaction',
		meta: { requiresAuth: true },
		component: EditTransaction,
	},
	{
		path: '/add-transaction/:date?',
		name: 'Add Transaction',
		meta: { requiresAuth: true },
		component: AddTransaction,
	},
	{
		path: '/signup',
		name: 'Sign Up Page',
		meta: { requiresAuth: false },
		component: SignUpPage,
	},
	{
		path: '/onelaststep',
		name: 'AdditionalQs',
		component: AdditionalQs,
		meta: { requiresAuth: true },
	},
	{
		path: '/reset_password',
		name: 'Reset Password',
		meta: { requiresAuth: false },
		component: ResetPasswordPage,
	},
	{
		path: '/login',
		name: 'Login',
		component: LoginPage,
		meta: { requiresAuth: false },
	},
	{
		path: '/:access_token',
		name: 'MagicLink',
		component: MagicLink,
		meta: { requiresAuth: false },
		beforeEnter: (to, from, next) => {
			if (
				to.params.access_token &&
				to.fullPath.includes('access_token=')
			) {
				next();
			} else {
				next('/login');
			}
		},
	},
	{
		path: '/oauth',
		name: 'MoneyKitOauth',
		component: MoneyKitOauth,
		meta: { requiresAuth: true },
	},
];

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async (to, from, next) => {
	const user = await getUser();

	if (to.meta.requiresAuth && user == null) {
		console.log('hi', to);
		next('/login');
	} else if (!to.meta.requiresAuth && !!user) {
		next('/');
	} else if (window.location.href.includes('moneykit')) {
		// Redirect to hash-based URL
		window.location.href = `/#/oauth${window.location.search}`;
	} else {
		next();
	}
});

router.afterEach((to) => {
	trackPageView(to.fullPath);
});

export default router;
