<script setup>
import { ref } from 'vue';
import { IonItem, IonRadio, IonRadioGroup, IonSpinner } from '@ionic/vue';
import { trackEvent } from '@/utils/analytics';

const disappointment = ref(null);
const emit = defineEmits(['next-step']);

const submitFeedback = () => {
	if (!disappointment.value) return;

	trackEvent('Disappointed without Lila', {
		disappointment: disappointment.value,
	});

	emit('next-step');
};
</script>

<template>
	<div class="calculating-state">
		<div class="spinning-section">
			<ion-img src="assets/cat/drum.gif" alt="Lila drumming" />
			<b>Drum roll!</b>
			<p class="spinning-text">
				<ion-spinner name="bubbles"></ion-spinner>
				Forecasting your spending
			</p>
		</div>
		<div>
			<p>
				<i>While you wait...</i>
				How would you feel if you could never use Lila again?
			</p>
			<ion-radio-group
				v-model="disappointment"
				@ionChange="submitFeedback">
				<ion-item>
					<ion-radio value="very"> Very Disappointed </ion-radio>
				</ion-item>

				<ion-item class="disappointed">
					<ion-radio value="somewhat">
						Somewhat Disappointed
					</ion-radio>
				</ion-item>

				<ion-item>
					<ion-radio value="not"> Not Disappointed </ion-radio>
				</ion-item>
			</ion-radio-group>
		</div>
	</div>
</template>

<style scoped>
.calculating-state {
	display: flex;
	flex-direction: column;
	height: 80vh;
	justify-content: space-between;
}

.spinning-section {
	height: 100%;
	padding-top: 10vh;
	text-align: center;
}

ion-img {
	margin: auto;
	margin-top: 2rem;
	width: 40vw;
	max-width: 300px;
}

.spinning-text {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
</style>
