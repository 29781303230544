<script setup>
import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonIcon,
	IonContent,
	IonImg,
	IonList,
	IonItem,
	IonInput,
	IonNote,
	IonButton,
	useIonRouter,
	loadingController,
	toastController,
} from '@ionic/vue';
import { computed, reactive, onMounted, ref } from 'vue';
import { arrowBackOutline } from 'ionicons/icons';
import { trackEvent } from '@/utils/analytics';
import {
	convertMonthGoalToWeekGoal,
	convertWeekGoalToMonthGoal,
} from '@/utils/spendPeriods';
import { getUser, supabase } from '@/utils/supabase';

const user = ref(null);
const router = useIonRouter();
const state = reactive({
	monthlyIdeal: null,
	fixedCosts: null,
});
const initialState = ref({
	monthlyIdeal: null,
	fixedCosts: null,
});

const idealVariableSpending = computed(() =>
	(state.monthlyIdeal - state.fixedCosts).toFixed(2)
);
const enableButton = computed(
	() =>
		state.monthlyIdeal &&
		state.fixedCosts &&
		state.monthlyIdeal - state.fixedCosts > 0 &&
		(state.monthlyIdeal != initialState.value.monthlyIdeal ||
			state.fixedCosts != initialState.value.fixedCosts)
);

const onSubmit = async () => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 8000 });

	try {
		const { error } = await supabase.from('user_info').upsert({
			user_id: user.value.id,
			ideal_weekly_goal: convertMonthGoalToWeekGoal(
				idealVariableSpending.value
			),
			monthly_fixed_costs: state.fixedCosts,
		});
		if (error)
			throw 'Unable to save your settings :( please try again later';

		trackEvent('New ideal goal saved');
		router.back();
	} catch (err) {
		trackEvent('Failed to set new ideal goal');
		toast.message = err?.message || err;
		await toast.present();
	} finally {
		loader.dismiss();
	}
};

const fetchIdealGoal = async () => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 8000 });

	try {
		await loader.present();
		const { data, error } = await supabase
			.from('user_info')
			.select('ideal_weekly_goal, monthly_fixed_costs')
			.eq('user_id', user.value.id)
			.single();

		if (error) throw error;

		const { ideal_weekly_goal, monthly_fixed_costs } = data;
		state.fixedCosts = monthly_fixed_costs;
		state.monthlyIdeal =
			Number(convertWeekGoalToMonthGoal(ideal_weekly_goal)) +
			monthly_fixed_costs;
		initialState.value.fixedCosts = state.fixedCosts;
		initialState.value.monthlyIdeal = state.monthlyIdeal;
	} catch (err) {
		toast.message = err.message;
		await toast.present();
	} finally {
		loader.dismiss();
	}
};

onMounted(async () => {
	user.value = await getUser();
	fetchIdealGoal();
});
</script>

<template>
	<ion-page class="idealgoal">
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button @click="router.back()">
						<ion-icon :icon="arrowBackOutline"></ion-icon>
					</ion-button>
				</ion-buttons>
				<ion-title>Update your ideal goal</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<div class="img-container">
				<ion-img alt="Lila the cat" src="/badge-lila.png" />
			</div>
			<form @submit.prevent="onSubmit">
				<ion-list>
					<p>
						Adjust the monthly spending goal that Lila helps you
						work towards!
					</p>

					<ion-item lines="none">
						<ion-input
							color="dark"
							required="true"
							label-placement="stacked"
							type="number"
							v-model="state.monthlyIdeal"
							name="monthlyIdeal"
							label="What's your ideal monthly spending?">
						</ion-input>
					</ion-item>

					<ion-item lines="none">
						<ion-input
							color="dark"
							required="true"
							label-placement="stacked"
							type="number"
							v-model="state.fixedCosts"
							name="fixedCosts"
							label="How much are your fixed costs?">
						</ion-input>
					</ion-item>

					<div
						v-if="state.fixedCosts && state.monthlyIdeal"
						class="ion-margin-bottom ion-margin-horizontal">
						<ion-note>
							This implies that your ideal variable spending is
							${{ idealVariableSpending }}. Does that look right?
						</ion-note>
					</div>

					<div
						class="ion-margin-bottom ion-margin-horizontal ion-text-end">
						<ion-button :disabled="!enableButton" type="submit">
							Save
						</ion-button>
					</div>
				</ion-list>
			</form>
		</ion-content>
	</ion-page>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap');

.idealgoal {
	--ion-color-primary: #b084f1;
	--ion-color-primary-rgb: 176, 132, 241;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #9b74d4;
	--ion-color-primary-tint: #b890f2;
	--ion-color-secondary: #e3fffa;
	--ion-color-secondary-rgb: 227, 255, 250;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #c8e0dc;
	--ion-color-secondary-tint: #e6fffb;
	--ion-color-tertiary: #ff52af;
	--ion-color-tertiary-rgb: 255, 82, 175;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #e0489a;
	--ion-color-tertiary-tint: #ff63b7;
	--ion-color-success: #e3fffa;
	--ion-color-success-rgb: 227, 255, 250;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #c8e0dc;
	--ion-color-success-tint: #e6fffb;
}

ion-content {
	--padding-start: 20px;
	--padding-end: 20px;
	--padding-top: 3rem;
}

.img-container {
	display: flex;
	justify-content: center;
}

.img-container ion-img {
	width: 40vw;
	max-width: 300px;
}

h1 {
	font-family: 'Judson', serif;
	font-weight: 700 !important;
	font-size: 40px !important;
	line-height: 100%;
	text-align: center;
	margin-top: 2rem;
	@media (max-width: 670px) {
		font-size: 40px;
	}
}

ion-item {
	--background: transparent;
	--padding-start: 0;
	--padding-end: 0;
	overflow: visible;
	width: 100%;
}

ion-label {
	white-space: normal;
}

ion-note {
	font-size: smaller;
}

.idealgoal ion-input {
	--highlight-height: 0px;
	margin-bottom: 15px;
	background-color: white;
	--color: #000;
	caret-color: black;
	--placeholder-color: #565656;
	--placeholder-opacity: 1;

	--border-radius: 15px;
	--border-color: #000;
	--border-style: solid;
	--border-width: 2px;
	--box-shadow: 4px 4px;
	border: 2px solid black;
	border-radius: 17px;
	box-shadow: 4px 4px;

	--padding-start: 20px;
	--padding-end: 20px;
	--padding-top: 4px;
	--padding-bottom: 4px;

	font-family: 'Ubuntu';
	font-weight: 700;
	--placeholder-font-weight: 500;

	width: 100%;
}

.idealgoal ion-content ion-button {
	--background: #d61d80;
	--background-hover: #c7237a;
	--background-activated: #c7237a;
	--background-focused: #c7237a;

	--color: white;

	--border-radius: 15px;
	--border-color: #000;
	--border-style: solid;
	--border-width: 2px;

	--box-shadow: 4px 4px black;

	--padding-top: 10px;
	--padding-bottom: 10px;

	text-transform: none;

	font-family: 'Ubuntu';
	font-weight: 700 !important;
}
</style>
