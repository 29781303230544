<script setup>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
	GridComponent,
	TooltipComponent,
	MarkPointComponent,
	GraphicComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import VChart from 'vue-echarts';
import { onMounted, ref, computed } from 'vue';
import { getUser, supabase } from '@/utils/supabase';
import { toastController } from '@ionic/vue';
import graphOptions from './graphOptions';
import { getDayNow, formatDate, getDayDifference } from '@/utils/dateRange';
import dayjs from 'dayjs';

use([
	CanvasRenderer,
	LineChart,
	GridComponent,
	TooltipComponent,
	MarkPointComponent,
	GraphicComponent,
]);
const props = defineProps({
	startDate: String,
	endDate: String,
	forecast: Number,
});

const options = ref(null);
const chart = ref(null);

const dailyIncrease = computed(
	() => props.forecast / getDayDifference(props.startDate, props.endDate)
);

const tooltipContent = (params) => {
	const currentDate = dayjs(params[0].axisValue);
	let content = `<b>${currentDate.format('ddd h:mm a')}</b><br/>`;

	// get greenline
	const yourSpending = params.find((s) => s.seriesName == 'Spent');
	if (yourSpending) {
		const amountSpent = yourSpending.value[1].toFixed(2);
		content += `<span style="color:${yourSpending.color};">●</span> ${yourSpending.seriesName}: <b>$${amountSpent}</b><br/>`;

		// distance from line
		const daysProgressed = getDayDifference(props.startDate, currentDate);
		const idealSpent = dailyIncrease.value * daysProgressed;
		const distanceToIdeal = amountSpent - idealSpent;
		if (idealSpent > 0) {
			content += `$${Math.abs(distanceToIdeal).toFixed(2)} ${distanceToIdeal > 0 ? 'above' : 'below'} the line!<br/>`;
		}
	}

	// forecast
	const forecast = params.find((s) => s.seriesName == 'Forecast');
	if (forecast && dayjs(props.endDate).isSame(currentDate)) {
		content += `<span style="color:${forecast.color};">●</span> Prediction: <b>$${props.forecast}</b><br/>`;
	}

	return content;
};

const fetchCheckIns = async (user) => {
	// returns an array of timestamps in string form e.g. ['2024-10-22T23:37:15.152000+00:00']
	const after = `after=${dayjs(props.startDate).format('YYYY-MM-DD')}`;
	const before = `before=${dayjs(props.endDate).add(1, 'day').format('YYYY-MM-DD')}`;
	const distinct_id = `distinct_id=${user.id}`;
	const url =
		'https://us.posthog.com/api/projects/80868/events/?event=%24set';

	try {
		const response = await fetch(
			[url, distinct_id, before, after].join('&'),
			{
				method: 'GET',
				headers: {
					Authorization: `Bearer ${process.env.VUE_APP_POSTHOG_READ_KEY}`,
					'Content-Type': 'application/json',
				},
			}
		);

		const data = await response.json();

		if (data.results.length === 0) return [];

		const MIN_TIME_DIFF = 3 * 60 * 60 * 1000; // 3 hours in milliseconds

		const filteredTimestamps = data.results.reduce((accumulator, event) => {
			const currentTimestamp = new Date(event.timestamp);

			if (accumulator.length === 0) {
				// Add the first timestamp
				accumulator.push(event.timestamp);
			} else {
				const lastKeptTimestamp = new Date(
					accumulator[accumulator.length - 1]
				);
				const timeDiff = Math.abs(currentTimestamp - lastKeptTimestamp);

				if (timeDiff >= MIN_TIME_DIFF) {
					accumulator.push(event.timestamp);
				}
			}

			return accumulator;
		}, []);

		return filteredTimestamps;
	} catch (error) {
		console.error('Error fetching user events:', error);
		return [];
	}
};

const addBackgroundMap = () => {
	if (!chart.value) return;

	// pointTopLeft + pointBottomRight are [x, y]
	const pointTopLeft = chart.value.convertToPixel({ seriesIndex: 0 }, [
		props.startDate,
		props.forecast,
	]);
	const pointBottomRight = chart.value.convertToPixel({ seriesIndex: 0 }, [
		props.endDate,
		0,
	]);

	chart.value.setOption({
		graphic: {
			type: 'image',
			style: {
				image: 'assets/mapimage.png',
				x: pointTopLeft[0],
				y: pointTopLeft[1],
				// note: echarts pixel grid is [0, 0] at the top left
				height: pointBottomRight[1] - pointTopLeft[1],
				width: pointBottomRight[0] - pointTopLeft[0],
			},
			silent: true, // don't respond to mouse / touch events (enables tooltip)
			z: -100,
		},
	});
};

const fetchTransacHistory = async (user) => {
	const toast = await toastController.create({ duration: 8000 });

	try {
		const { data } = await supabase.rpc('get_transaction_totals', {
			user_id: user.id,
			start_date: props.startDate,
			end_date: props.endDate,
		});

		let chartValues = [];
		let spent = 0;
		if (data.length) {
			chartValues = data.map((d) => [d.datetime, d.total]);
			spent = data[data.length - 1].total;
		}
		// Add current or last moment to chart data
		if (
			new Date() < new Date(props.endDate) &&
			new Date() > new Date(props.startDate)
		) {
			chartValues.push([getDayNow(), spent]);
		} else if (new Date() > new Date(props.endDate)) {
			chartValues.push([formatDate(props.endDate, 'end'), spent]);
		}

		// Add data to chart
		graphOptions.series[1].data = [[props.startDate, 0], ...chartValues];
		graphOptions.series[1].markPoint.data[0].coord =
			chartValues[chartValues.length - 1];
		graphOptions.series[0].data = [
			[props.startDate, dailyIncrease.value],
			[props.endDate, props.forecast],
		];
		graphOptions.series[0].markPoint.data[0].coord = [
			props.endDate,
			props.forecast,
		];

		// Make the chart x-axis show days of the week if it's a week-view
		if (dayjs(props.endDate).diff(dayjs(props.startDate), 'day') < 7)
			graphOptions.xAxis.axisLabel = { formatter: '               {ee}' };

		// Format the tooltip
		graphOptions.tooltip.formatter = tooltipContent;

		// Place check-in goodies
		const checkInGoodiesStamps = await fetchCheckIns(user);
		checkInGoodiesStamps.forEach((xValPlace) => {
			const yValPlace = graphOptions.series[1].data.findLast((el) =>
				dayjs(el[0]).isBefore(dayjs(xValPlace))
			)[1];
			graphOptions.series[1].markPoint.data.push({
				symbol: 'image://assets/checkin_pin.png',
				symbolSize: [24, 24],
				label: { show: false },
				coord: [xValPlace, yValPlace + 8], // +8 makes room for image
			});
		});

		options.value = graphOptions;
	} catch (err) {
		toast.message = err;
		await toast.present();
	}
};

onMounted(async () => {
	const user = await getUser();
	fetchTransacHistory(user).then(addBackgroundMap);
});
</script>

<template>
	<v-chart
		ref="chart"
		:option="options"
		:loading="!options"
		:class="{ chart: true, loading_complete: options }" />
</template>

<style scoped>
.chart {
	height: 250px;
	padding: 1rem;
}
</style>
