<script setup>
import {
	IonPage,
	IonTabs,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonLabel,
	IonIcon,
	useIonRouter,
} from '@ionic/vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import AvatarImage from './profile/_components/AvatarImage.vue';
import { cashOutline, library } from 'ionicons/icons';

const route = useRoute();
const ionRouter = useIonRouter();
const currentPath = computed(() => route.path);
const goto = (path) => ionRouter.replace(`/${path}`);
</script>

<template>
	<ion-page>
		<ion-tabs>
			<ion-router-outlet></ion-router-outlet>

			<ion-tab-bar slot="bottom">
				<ion-tab-button
					@click="goto('')"
					class="home-button"
					:class="{ 'active-tab': currentPath == '/' }">
					<ion-icon :icon="cashOutline" />
					<ion-label>Home</ion-label>
				</ion-tab-button>

				<ion-tab-button
					@click="goto('spend-settings')"
					class="settings-button"
					:class="{ 'active-tab': currentPath == '/spend-settings' }">
					<ion-icon :icon="library" />
					<ion-label>Spend History</ion-label>
				</ion-tab-button>

				<ion-tab-button
					@click="goto('profile')"
					class="profile-button"
					:class="{ 'active-tab': currentPath == '/profile' }">
					<div class="avatar-container">
						<AvatarImage :active="currentPath == '/profile'" />
					</div>
					<ion-label>Profile</ion-label>
				</ion-tab-button>
			</ion-tab-bar>
		</ion-tabs>
	</ion-page>
</template>

<style scoped>
.avatar-container {
	width: 24px;
	height: 24px;
}
ion-label {
	font-size: 10px;
}
.active-tab {
	--ion-color-primary: #b084f1;
	color: var(--ion-color-primary);
}
</style>
