import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getUser, supabase } from '@/utils/supabase';
import { trackEvent } from './analytics';

const firebaseConfig = {
	apiKey: 'AIzaSyCu1l6SZlPStJDaBwk6X4r4IcZbD62Wpas',
	authDomain: 'feelgoodfinanceapp-2b8c9.firebaseapp.com',
	projectId: 'feelgoodfinanceapp-2b8c9',
	storageBucket: 'feelgoodfinanceapp-2b8c9.appspot.com',
	messagingSenderId: '409819992179',
	appId: '1:409819992179:web:8f5043b42c479d06ac5a06',
	measurementId: 'G-X9CLQM1W0V',
};

initializeApp(firebaseConfig);

// #region foreground notifs
const messaging = getMessaging();
onMessage(messaging, (payload) => {
	console.log(
		'Message received. ',
		payload,
		Notification,
		Notification.permission
	);

	if (Notification.permission === 'granted') {
		const { title, body, icon } = payload.data || {};

		// Create and display the notification
		const notification = new Notification(title || 'Default Title', {
			body: body || '',
			icon: icon || '/apple-touch-icon.png',
		});

		notification.onclick = async () => {
			window.onfocus();
			trackEvent('Foreground Notification Click', {
				url: window.location.href,
			});
		};
	}
});
// #endregion foreground notifs

export async function requestNotificationPermission() {
	const userId = (await getUser()).id;
	if (!userId) return;
	let token;

	try {
		token = await getToken(messaging, {
			vapidKey: process.env.VUE_APP_VAPID_KEY,
		});
	} catch (err) {
		throw 'Notifications are taking a second to connect, try again';
	}

	if (token) {
		const { error } = await supabase
			.from('user_info')
			.upsert({ user_id: userId, fcm_token: token });
		if (error)
			throw "Notifications are setup but Lila couldn't connect with them, please try again";
	} else {
		throw 'Notifications are not available at this time, try again';
	}
}
