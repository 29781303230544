<script setup>
import { ref, onMounted } from 'vue';
import { IonImg } from '@ionic/vue';
import cats from './cats';
const props = defineProps({
	percentSpent: {
		type: Number,
		required: true,
		default: 0,
	},
});
const currentCat = ref(null);

const setCurrentCat = () => {
	let mood;
	if (props.percentSpent >= 1) {
		mood = cats.upset;
	} else if (props.percentSpent >= 0.75) {
		mood = cats.worried;
	} else if (props.percentSpent >= 0.25) {
		mood = cats.hangingout;
	} else {
		mood = cats.chill;
	}

	currentCat.value = mood[Math.floor(Math.random() * mood.length)];
};

onMounted(() => {
	setCurrentCat();
});
</script>

<template>
	<div class="background">
		<ion-img
			v-if="currentCat"
			:src="currentCat.img"
			:alt="currentCat.alt" />
	</div>
</template>

<style scoped>
.background {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #dccef8;
}

ion-img {
	padding: 3rem 1rem;
	height: 40vh;
	width: 40vh;
}
</style>
