<script setup>
import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonIcon,
	IonContent,
	IonImg,
	IonList,
	IonItem,
	IonBadge,
	IonButton,
	useIonRouter,
	loadingController,
	toastController,
} from '@ionic/vue';
import { onMounted, ref } from 'vue';
import { arrowBackOutline } from 'ionicons/icons';
import { getUser, supabase } from '@/utils/supabase';
import badgeData from './badges';
import { trophyOutline } from 'ionicons/icons';

const user = ref(null);
const router = useIonRouter();
const badges = ref(null);

const fetchBadges = async () => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 8000 });

	try {
		await loader.present();

		const { data, error } = await supabase.rpc('get_user_badges', {
			user_id_input: user.value.id,
		});
		if (error) throw error;

		if (data && data.length > 0) {
			badges.value = data;
		}
	} catch (err) {
		toast.message = err.message || err;
		await toast.present();
	} finally {
		await loader.dismiss();
	}
};

onMounted(async () => {
	user.value = await getUser();
	fetchBadges();
});
</script>

<template>
	<ion-page class="idealgoal">
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button @click="router.back()">
						<ion-icon :icon="arrowBackOutline"></ion-icon>
					</ion-button>
				</ion-buttons>
				<ion-title>View all your badges</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<div class="img-container">
				<ion-img alt="Lila the cat" src="/badge-lila.png" />
			</div>
			<ion-list v-if="badges">
				<p>
					Here are all the badges you've earned! (And a few you're
					still earning)
				</p>
				<ion-item v-for="b in badges" v-bind:key="b.type">
					<ion-icon
						color="primary"
						aria-hidden="true"
						:icon="trophyOutline"
						slot="start"></ion-icon>
					<ion-label>
						<strong>{{ badgeData[b.type].name }}</strong>
						<p>
							{{ badgeData[b.type].description }}
						</p>
					</ion-label>
					<ion-badge>
						{{ b.number }}
					</ion-badge>
				</ion-item>
			</ion-list>
			<div v-else>
				You earn badges for doing well across the app. Earn your first
				badge by spending under the forecast this week!
			</div>
		</ion-content>
	</ion-page>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap');

.idealgoal {
	--ion-color-primary: #b084f1;
	--ion-color-primary-rgb: 176, 132, 241;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #9b74d4;
	--ion-color-primary-tint: #b890f2;
	--ion-color-secondary: #e3fffa;
	--ion-color-secondary-rgb: 227, 255, 250;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #c8e0dc;
	--ion-color-secondary-tint: #e6fffb;
	--ion-color-tertiary: #ff52af;
	--ion-color-tertiary-rgb: 255, 82, 175;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #e0489a;
	--ion-color-tertiary-tint: #ff63b7;
	--ion-color-success: #e3fffa;
	--ion-color-success-rgb: 227, 255, 250;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #c8e0dc;
	--ion-color-success-tint: #e6fffb;
}

ion-content {
	--padding-start: 20px;
	--padding-end: 20px;
	--padding-top: 3rem;
}

.img-container {
	display: flex;
	justify-content: center;
}

.img-container ion-img {
	width: 40vw;
	max-width: 300px;
}

h1 {
	font-family: 'Judson', serif;
	font-weight: 700 !important;
	font-size: 40px !important;
	line-height: 100%;
	text-align: center;
	margin-top: 2rem;
	@media (max-width: 670px) {
		font-size: 40px;
	}
}

ion-item {
	--background: transparent;
	--padding-start: 0;
	--padding-end: 0;
	overflow: visible;
	width: 100%;
}

ion-label {
	white-space: normal;
}

ion-note {
	font-size: smaller;
}

.idealgoal ion-input {
	--highlight-height: 0px;
	margin-bottom: 15px;
	background-color: white;
	--color: #000;
	caret-color: black;
	--placeholder-color: #565656;
	--placeholder-opacity: 1;

	--border-radius: 15px;
	--border-color: #000;
	--border-style: solid;
	--border-width: 2px;
	--box-shadow: 4px 4px;
	border: 2px solid black;
	border-radius: 17px;
	box-shadow: 4px 4px;

	--padding-start: 20px;
	--padding-end: 20px;
	--padding-top: 4px;
	--padding-bottom: 4px;

	font-family: 'Ubuntu';
	font-weight: 700;
	--placeholder-font-weight: 500;

	width: 100%;
}

.idealgoal ion-content ion-button {
	--background: #d61d80;
	--background-hover: #c7237a;
	--background-activated: #c7237a;
	--background-focused: #c7237a;

	--color: white;

	--border-radius: 15px;
	--border-color: #000;
	--border-style: solid;
	--border-width: 2px;

	--box-shadow: 4px 4px black;

	--padding-top: 10px;
	--padding-bottom: 10px;

	text-transform: none;

	font-family: 'Ubuntu';
	font-weight: 700 !important;
}
</style>
