<template>
	<ion-page>
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button @click="this.goBack()">
						<ion-icon :icon="arrowBackOutline"></ion-icon>
					</ion-button>
				</ion-buttons>
				<ion-title>Edit Transaction</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true">
			<div id="container">
				<ion-item>
					<ion-label>Name</ion-label>
					<ion-input v-model="this.transac.name"></ion-input>
				</ion-item>
				<ion-item>
					<ion-label>Date</ion-label>
					<ion-datetime-button
						datetime="datetime"></ion-datetime-button>
				</ion-item>
				<ion-item>
					<ion-label>Amount</ion-label>
					<ion-input
						type="number"
						v-model="this.transac.amount"></ion-input>
				</ion-item>
				<p v-if="v$.transac.amount.$invalid" class="error-text">
					{{
						'Please enter a positive number with upto 2 decimal places, eg: 123, 12.23, 20, 2'
					}}
				</p>
				<ion-buttons slot="end">
					<ion-button fill="solid" color="danger" @click="deleteIt">
						Delete
					</ion-button>
					<ion-button
						fill="solid"
						@click="saveTransac"
						:disabled="
							transac.amount === null ||
							v$.transac.datetime.$invalid ||
							v$.transac.amount.$invalid
						">
						Save
					</ion-button>
				</ion-buttons>
			</div>
			<ion-modal :keep-contents-mounted="true">
				<ion-datetime
					id="datetime"
					presentation="time-date"
					v-model="transac.datetime"></ion-datetime>
			</ion-modal>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonItem,
	IonLabel,
	IonButton,
	IonButtons,
	IonInput,
	IonIcon,
	isPlatform,
	IonDatetime,
	IonDatetimeButton,
	IonModal,
} from '@ionic/vue';
import { arrowBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { delTransactions } from '../utils/transactions.js';
import { containsPositiveFloat } from '@/utils/validations';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { formatDate, formatDateLocalISO } from '@/utils/dateRange.js';
import { trackEvent } from '@/utils/analytics';
import { supabase } from '@/utils/supabase';

export default defineComponent({
	name: 'HomePage',
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonLabel,
		IonButton,
		IonButtons,
		IonInput,
		IonIcon,
		IonDatetime,
		IonDatetimeButton,
		IonModal,
	},
	setup() {
		return { arrowBackOutline, v$: useVuelidate() };
	},
	async created() {
		try {
			const { data, error } = await supabase
				.from('transactions_w_date')
				.select('user_id, transaction_id, name, amount, datetime')
				.eq('transaction_id', this.$route.params.id);
			if (error) {
				throw error.message;
			}
			this.transac = {
				...data[0],
				datetime: formatDateLocalISO(data[0].datetime),
			};
		} catch (err) {
			console.error(err);
		}
	},
	data() {
		return {
			id: null,
			transac: {
				name: '',
				amount: 0,
				datetime: null,
			},
			dollar: Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
			}),
		};
	},
	validations: {
		transac: {
			datetime: {
				required,
			},
			amount: {
				required,
				containsPositiveFloat,
			},
		},
	},
	methods: {
		redirectReload() {
			this.$router.push({ path: '/' }).then(() => {
				this.$router.go();
			});
		},
		deleteIt() {
			delTransactions(this.transac.transaction_id).then(() => {
				this.goBack();
			});
		},
		async saveTransac() {
			let t = {
				...this.transac, // unrolling Promise to be Object
				amount: this.transac.amount * 1, // ensure typeOf num
				datetime: formatDate(this.transac.datetime),
			};
			try {
				const { error } = await supabase.from('transactions').upsert(t);
				if (error) {
					throw error.message;
				}
				trackEvent('Transaction Edited');
			} catch (err) {
				console.error(err);
			} finally {
				this.goBack();
			}
		},
		goBack() {
			this.$router.push({ path: '/' });
			if (isPlatform('mobile')) {
				this.redirectReload();
			}
		},
	},
});
</script>

<style scoped>
#container {
	text-align: center;

	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);

	max-width: 30rem;
	margin: auto;
}

ion-input {
	text-align: end;
}

ion-buttons {
	padding: 20px;
	gap: 20px;
}

ion-button {
	width: 100%;
}

#container p.error-text {
	font-size: x-small;
	color: #ab3030;
	--min-height: 16px;
	padding: 0 20px;
}
</style>
