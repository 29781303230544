/**
 * Functions to interface with Login, SignUp and any auth pages
 * This is used to validate the inputs for mainly passwords and emails, but can be utilized in other ways later
 *
 */

export const containsUppercase = (value) => {
	return /[A-Z]/.test(value);
};

export const containsLowercase = (value) => {
	return /[a-z]/.test(value);
};

export const containsNumber = (value) => {
	return /[0-9]/.test(value);
};

export const containsSpecial = (value) => {
	return /[#?!@$%^&*-]/.test(value);
};

export const containsDate = (value) => {
	let val =
		/\d{1,2}\s(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?)\s\d{4}/.test(
			value
		);
	console.log(val);

	return /\d{1,2}\s(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?)\s\d{4}/.test(
		value
	);
};

export const containsPositiveFloat = (value) => {
	// console.log(/^(?:\d*\.\d{1,2}|\d+)$/.test(value))
	return /^(?:\d*\.\d{1,2}|\d+)$/.test(value);
};

export const validationMessages = {
	required: 'Password required!',
	minLength: 'A min length of 6 characters',
	containsUppercase: 'At least 1 uppercase character',
	containsLowercase: 'At least 1 lowercase character',
	containsNumber: 'At least 1 number (e.g., 1,2,3,4)',
	containsSpecial: 'At least 1 special character (e.g., !$^*~)',
};
