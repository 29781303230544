import posthog from 'posthog-js';

const isNotProd = process.env.VUE_APP_ENV !== 'production';

/**
 * This module provides a singleton pattern implementation for initializing and accessing the PostHog analytics instance.
 * It also includes utility functions for tracking page views, identifying users, and tracking custom events.
 *
 * PostHog is a product analytics tool that allows you to track user interactions in your application.
 * By centralizing the initialization and usage of the PostHog instance, this module ensures that the instance is
 * only created once and is easily accessible throughout your application.
 */

class Analytics {
	constructor() {
		if (!Analytics.instance) {
			Analytics.instance = null;
		}
	}

	/**
	 * Initializes the PostHog instance with the provided API key and options.
	 * This method should be called once when your application starts.
	 *
	 * @param {string} apiKey - The API key for your PostHog project.
	 * @param {object} options - Additional configuration options for PostHog.
	 */
	static init(apiKey, options) {
		if (isNotProd) {
			console.log(
				`PostHog tracking is disabled for ${process.env.VUE_APP_ENV} environment.`
			);
		} else if (!Analytics.instance) {
			Analytics.instance = posthog.init(apiKey, options);
		}
	}

	static getInstance() {
		if (isNotProd) return;
		if (!Analytics.instance) {
			throw new Error(
				'PostHog instance is not initialized. Call init first.'
			);
		}
		return Analytics.instance;
	}
}

export const initAnalytics = (apiKey, options) =>
	Analytics.init(apiKey, options);

export const trackPageView = (path) => {
	if (isNotProd) console.log('trackPageView', { path });
	let instance = Analytics.getInstance();
	if (path && instance) {
		instance.capture('$pageview', { path });
	}
};

export const identifyUser = (session) => {
	if (isNotProd) console.log('identifyUser', { session });
	const instance = Analytics.getInstance();
	if (instance && session) {
		instance.identify(session.user.id, {
			email: session.user.email,
		});
	}
};

export const trackUser = (session, action) => {
	if (isNotProd) console.log('trackUser', { session, action });
	const instance = Analytics.getInstance();
	if (instance && session) {
		instance.capture(action, {
			email: session.user.email,
			user_id: session.user.id,
		});
	}
};

export const trackEvent = (action, data = {}) => {
	if (isNotProd) console.log('trackEvent', { action, data });
	const instance = Analytics.getInstance();
	if (instance) {
		instance.capture(action, data);
	}
};
