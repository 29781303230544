<script setup>
import { ref, computed } from 'vue';
import { IonItem, IonRange, IonIcon, IonToggle, IonButton } from '@ionic/vue';
import {
	arrowUpCircleOutline,
	arrowDownCircleOutline,
	banOutline,
	checkmarkCircleOutline,
} from 'ionicons/icons';
import { supabase } from '@/utils/supabase';
import dayjs from 'dayjs';

const props = defineProps({
	nextSpendingPeriod: Object,
});

const emit = defineEmits(['next-step']);
const specialSpending = ref(0);
const anticipateSpending = ref(0);
const spendingPhase = ref(false);
const closestMonday = computed((date = dayjs()) => {
	let day = date.day();
	if (day === 1) {
		return date.format('MMM D');
	} else if (day >= 2 && day <= 4) {
		return date.subtract(day - 1, 'day').format('MMM D');
	} else {
		return date.add(8 - day, 'day').format('MMM D');
	}
});

const submitQuiz = async () => {
	const nextPeriod = {
		...props.nextSpendingPeriod,
		quiz_special_spending: specialSpending.value,
		quiz_anticipate_spending: anticipateSpending.value,
		quiz_spending_phase: spendingPhase.value,
	};

	const { error } = await supabase.from('spend_history').upsert(nextPeriod, {
		onConflict: ['user_id', 'start_date', 'end_date'],
		// update the values with the same start_date + end_date
		ignoreDuplicates: false,
	});
	if (error) throw new Error('Error upserting spend history', error);

	emit('next-step');
};
</script>

<template>
	<div class="container">
		<strong class="title"> New week. New me. 😽 </strong>
		<p>
			{{ closestMonday }} is here, it's the start of a new week. Fill out
			the quiz to make it another week of purrfect spending
		</p>
		<form @submit.prevent="submitQuiz">
			<ion-item class="ion-no-padding">
				<ion-range
					label-placement="stacked"
					label="Any special spending this week?"
					:ticks="true"
					:snaps="true"
					:min="0"
					:max="5"
					required="true"
					v-model="specialSpending"
					name="events">
					<ion-icon slot="start" :icon="banOutline"></ion-icon>
					<ion-icon
						slot="end"
						:icon="checkmarkCircleOutline"></ion-icon>
				</ion-range>
			</ion-item>
			<ion-item class="ion-margin-vertical ion-no-padding">
				<ion-range
					label-placement="stacked"
					label="Do you anticipate your spending to be higher/lower than last week?"
					:ticks="true"
					:snaps="true"
					:min="-3"
					:max="3"
					required="true"
					name="spending_diff"
					v-model="anticipateSpending">
					<ion-icon
						slot="start"
						:icon="arrowDownCircleOutline"></ion-icon>
					<ion-icon
						slot="end"
						:icon="arrowUpCircleOutline"></ion-icon>
				</ion-range>
			</ion-item>
			<ion-item class="ion-margin-vertical ion-no-padding">
				<ion-label class="ion-text-wrap">
					Are you in a spending phase this week?
				</ion-label>
				<ion-toggle
					:enable-on-off-labels="true"
					required="true"
					name="spending_phase"
					v-model="spendingPhase">
				</ion-toggle>
			</ion-item>

			<div>
				<ion-button
					class="submit-button"
					expand="block"
					color="success"
					type="submit">
					Submit
				</ion-button>
			</div>
		</form>
	</div>
</template>

<style scoped>
.container {
	padding-top: 1rem;
}

.title {
	font-size: 1.2rem;
}

.ion-text-wrap {
	overflow: visible;
	font-size: 1rem;
}
</style>
