import { supabase, getUser } from '@/utils/supabase';

export async function setTransactions(transactions) {
	const user = await getUser();
	const userTransactions = transactions.map((t) => ({
		...t,
		user_id: user.id,
	}));

	const { error } = await supabase
		.from('transactions')
		.upsert(userTransactions);

	if (error) throw new Error(error.message, error);
}

export async function delTransactions(transaction_id) {
	const { error } = await supabase
		.from('transactions')
		.delete()
		.eq('transaction_id', transaction_id);

	if (error) throw new Error(error.message, error);
}

/**
 * Helper functions
 */
function isObject(object) {
	return object != null && typeof object === 'object';
}

export function isEqual(obj1, obj2) {
	var props1 = Object.getOwnPropertyNames(obj1);
	var props2 = Object.getOwnPropertyNames(obj2);
	if (props1.length != props2.length) {
		return false;
	}
	for (var i = 0; i < props1.length; i++) {
		let val1 = obj1[props1[i]];
		let val2 = obj2[props1[i]];
		let isObjects = isObject(val1) && isObject(val2);
		if (
			(isObjects && !isEqual(val1, val2)) ||
			(!isObjects && val1 !== val2)
		) {
			return false;
		}
	}
	return true;
}
