<script setup>
import {
	IonPage,
	IonList,
	IonItem,
	IonInput,
	IonButton,
	loadingController,
	toastController,
	useIonRouter,
} from '@ionic/vue';
import { ref } from 'vue';
import { supabase } from '@/utils/supabase';
import { identifyUser, trackEvent, trackUser } from '@/utils/analytics';

const email = ref();
const password = ref();
const ionRouter = useIonRouter();

const onSubmit = async () => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 5000 });

	try {
		loader.present();

		let { data, error } = await supabase.auth.signInWithPassword({
			email: email.value,
			password: password.value,
		});

		if (error) {
			throw error.message;
		}
		identifyUser(data.session);
		trackUser(data.session, 'SIGN_IN');
		ionRouter.push('/');
	} catch (err) {
		trackEvent('Error logging in', err);
		toast.message = err;
		await toast.present();
	} finally {
		loader.dismiss();
	}
};

const sendMagicLink = async () => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 5000 });

	try {
		loader.present();

		let { error } = await supabase.auth.signInWithOtp({
			email: email.value,
			options: {
				shouldCreateUser: false,
				emailRedirectTo: `${window.location.origin}/`,
			},
		});

		if (error) {
			throw error.message;
		}
		toast.message =
			'Purrfect! ✨ Magic ✨ link sent. Go check your email hooman!';
		await toast.present();
	} catch (err) {
		trackEvent('Error with Magic Link', err);
		toast.message = err;
		await toast.present();
	} finally {
		loader.dismiss();
	}
};
</script>

<template>
	<ion-page class="login-page">
		<form @submit.prevent="onSubmit" autocomplete="on">
			<div class="top">
				<h1>Welcome Back!</h1>
			</div>
			<ion-list>
				<ion-item lines="none">
					<ion-input
						color="dark"
						label="Email"
						v-model="email"
						autocomplete="email"
						name="email"
						required="true"
						type="email" />
				</ion-item>
				<ion-item lines="none">
					<ion-input
						color="dark"
						label="Password"
						v-model="password"
						autocomplete="current-password"
						name="password"
						required="true"
						type="password" />
				</ion-item>

				<div class="login-buttons">
					<ion-button expand="block" color="success" type="submit">
						Log in
					</ion-button>

					<ion-button
						expand="block"
						color="primary"
						@click.prevent="sendMagicLink">
						Send Magic Link
					</ion-button>
				</div>
			</ion-list>

			<div class="signup">
				<p>Don't have an account? Go signup!</p>
				<ion-button fill="clear" href="/#/signup"> Sign up </ion-button>
			</div>
		</form>
	</ion-page>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap');

h1 {
	font-family: 'Judson', serif;
	font-weight: 700 !important;
	font-size: 40px !important;
	line-height: 100%;
	@media (max-width: 670px) {
		font-size: 40px;
	}
}

.login-page {
	--ion-color-primary: #b084f1;
	--ion-color-primary-rgb: 176, 132, 241;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #9b74d4;
	--ion-color-primary-tint: #b890f2;
	--ion-color-secondary: #e3fffa;
	--ion-color-secondary-rgb: 227, 255, 250;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #c8e0dc;
	--ion-color-secondary-tint: #e6fffb;
	--ion-color-tertiary: #ff52af;
	--ion-color-tertiary-rgb: 255, 82, 175;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #e0489a;
	--ion-color-tertiary-tint: #ff63b7;
	--ion-color-success: #e3fffa;
	--ion-color-success-rgb: 227, 255, 250;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #c8e0dc;
	--ion-color-success-tint: #e6fffb;

	font-family: 'Ubuntu', serif;
	--ion-font-family: 'Ubuntu';
	--ion-font-size: 15px;
	line-height: 150%;

	align-items: center;
	justify-content: center !important;
	background: radial-gradient(
			farthest-corner at 0% 20%,
			#caa6ff,
			transparent 35%
		),
		radial-gradient(farthest-side at 60% 0%, #d0ffb8, transparent 30%),
		radial-gradient(farthest-corner at 100% 40%, #7bffa9, transparent 35%),
		radial-gradient(farthest-corner at 65% 100%, #a274ff, transparent 65%),
		radial-gradient(farthest-side at 0% 100%, #d0ffb8, transparent 70%);
}

form {
	text-align: center;
	margin-left: auto;
	margin-right: auto;

	display: flex;
	flex-direction: column;
	gap: 2rem;
	height: calc(100vh - 20px * 2);
	width: calc(100vw - 20px * 2);
	background: var(--ion-background-color, white);
	color: var(--ion-text-color, #000);
	justify-content: center;

	@media (max-width: 568px) {
		min-width: none;
	}
}

.login-page ion-item ion-input {
	--background: #c7c9ff;
	--color: #000;
	caret-color: black;

	--placeholder-color: #1a1a1a;
	--placeholder-opacity: 1;
	--placeholder-font-weight: 400;

	--border-radius: 15px;
	border-radius: 17px;
	--border-color: #000;
	--border-style: solid;
	--border-width: 2px;
	border: 2px solid black;

	--box-shadow: 4px 4px;
	box-shadow: 4px 4px;

	--padding-start: 20px;
	--padding-end: 20px;
	--padding-top: 11px;
	--padding-bottom: 11px;

	--highlight-height: 0px;
	margin-bottom: 15px;
	font-weight: 700;

	font-family: 'Ubuntu';
	font-weight: 700;
	--placeholder-font-weight: 500;

	max-width: 300px;
	margin-left: auto;
	margin-right: auto;
}

.login-page ion-list {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0;
	text-align: center;
	background-color: transparent;
}

.login-buttons {
	display: flex;
	flex-direction: row;
	gap: 2rem;
	justify-content: center;
}

.signup p {
	margin-bottom: 5px;
}

ion-item {
	--background: transparent;
	overflow: visible;
	width: 100%;
}

.login-page ion-button {
	--background: #d61d80;
	--background-hover: #c7237a;
	--background-activated: #c7237a;
	--background-focused: #c7237a;

	--color: white;

	--border-radius: 15px;
	--border-color: #000;
	--border-style: solid;
	--border-width: 2px;

	--box-shadow: 4px 4px black;

	--padding-top: 10px;
	--padding-bottom: 10px;

	text-transform: none;

	font-family: 'Ubuntu';
	font-weight: 700 !important;
}
</style>
