<script setup>
import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	IonButton,
	IonList,
	loadingController,
	toastController,
} from '@ionic/vue';
import { onMounted, ref, computed } from 'vue';
import { supabase, getUser } from '@/utils/supabase';
import { requestNotificationPermission } from '@/utils/firebase';
import dayjs from 'dayjs';
import { trackEvent } from '@/utils/analytics';

// #region cat counter
const counter = computed(() => {
	const today = dayjs().day();
	return today === 0 ? 1 : 8 - today;
});
// #endregion

// #region notifications
const notificationStatus = ref(false);

const allowNotifs = async () => {
	trackEvent('countdown page, clicked allow/reconnect notifications');
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 8000 });

	try {
		await loader.present();
		await requestNotificationPermission();
	} catch (err) {
		toast.message = err.message || err;
		await toast.present();
	} finally {
		loader.dismiss();
		if (Notification.permission == 'granted') {
			notificationStatus.value = true;
			toast.message = 'Notifications are now enabled!';
			await toast.present();
		}
	}
};
// #endregion

// #region transactions
const transactions = ref([]);

const fetchLastestTransactions = async () => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 8000 });
	try {
		await loader.present();
		getUser().then(async (user) => {
			const { data, error } = await supabase
				.from('transactions_w_date')
				.select('name, amount, datetime')
				.eq('user_id', user.id)
				.limit(3)
				.order('datetime', { ascending: false });
			if (error) throw error;

			transactions.value = data;
		});
	} catch (error) {
		toast.message = error.message || error;
		await toast.present();
	} finally {
		loader.dismiss();
	}
};
// #endregion

onMounted(async () => {
	fetchLastestTransactions();
	if (Notification.permission == 'granted') {
		notificationStatus.value = true;
	}
});
</script>

<template>
	<ion-header>
		<ion-toolbar>
			<ion-title>Welcome to Lila!</ion-title>
		</ion-toolbar>
	</ion-header>
	<ion-page>
		<ion-content class="ion-padding">
			<section class="counter">
				<div class="columns">
					<h1 class="rows">
						<p>{{ counter }}</p>
						<b>DAYS LEFT!</b>
					</h1>
					<img
						src="assets/cat/hangingout_party.PNG"
						alt="Lila the cat is at the party, wearing a party cat with sparkles all around her" />
				</div>
			</section>

			<section>
				<h2>Start counting down!</h2>
				<p>
					Start using the app on Monday, when your spending cycle
					begins!
				</p>
			</section>

			<section class="enable-notifications" v-if="!notificationStatus">
				<h2>Allow notifications</h2>
				<p>We’ll send you a notification so you won’t miss it.</p>
				<div>
					<ion-button @click="allowNotifs">
						Turn on notifications
					</ion-button>
				</div>
			</section>

			<section class="check-transactions">
				<h2>Verify transactions</h2>
				<div v-if="transactions.length > 0">
					<p>The app is picking up your transactions!!</p>
					<p>
						<a href="https://instagram.com/LilaTheMoneyCat">
							Contact us on Instagram
						</a>
						if this isn't working as expected
					</p>
					<h3>History</h3>
					<ion-list>
						<ion-item
							v-for="item in transactions"
							:key="item"
							lines="none">
							<ion-label>
								<h2>
									{{ item.name }}
								</h2>
								<p>
									{{ dayjs(item.datetime).format('MMM DD') }}
								</p>
							</ion-label>
							<div slot="end">
								<p>${{ item.amount.toFixed(2) }}</p>
							</div>
						</ion-item>
					</ion-list>
				</div>
				<div v-else>
					<p>We haven't logged any transactions yet!</p>
					<p>
						Purchases should take less than 1h to show up in the
						app.
						<a href="https://instagram.com/LilaTheMoneyCat">
							Contact us on Instagram
						</a>
						if nothing is showing up!
					</p>
				</div>
			</section>
		</ion-content>
	</ion-page>
</template>

<style scoped>
ion-content {
	--padding-top: 3rem;
	--padding-bottom: 1rem;
	--ion-color-secondary: #dcd6f6;
	--ion-item-background: transparent;
}
section {
	margin-top: 3rem;
}

/* #region counter */
.counter {
	text-align: center;
	margin: 2rem 0;
}

.counter .columns {
	display: grid;
	grid-template-columns: 50% 50%;

	width: calc(100% - 10px);
	position: relative;
	padding: 1rem 2rem;
	border: 3px solid black;
	border-radius: 30px;
	box-shadow: black 5px 5px;
	background-color: var(--ion-color-secondary);
}

.counter .columns img {
	width: 45vw;
	justify-self: center;
	position: absolute;
	right: 0px;
	top: -30px;
}

.counter .columns .rows {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	margin: 0;
	font-size: 1.5rem;
}

.counter .columns .rows p {
	font-size: 3rem;
	margin: 0;
	font-weight: bold;
}
/* #endregion counter */

section:not(.counter):not(.check-transactions) {
	margin-left: 1rem;
	margin-right: 1rem;
}

ion-button {
	--background: #d61d80;
	--background-hover: #c7237a;
	--background-activated: #c7237a;
	--background-focused: #c7237a;

	--color: white;

	--border-radius: 30px;
	--border-color: #000;
	--border-style: solid;
	--border-width: 2px;

	--box-shadow: 4px 4px black;

	--padding-top: 10px;
	--padding-bottom: 10px;

	text-transform: none;

	font-family: 'Ubuntu', sans-serif;
	font-weight: bold;
}

/* #region check-transactions */
.check-transactions {
	width: calc(100% - 10px);
	padding: 1rem 2rem;
	border: 3px solid black;
	border-radius: 30px;
	box-shadow: black 5px 5px;
	background-color: var(--ion-color-secondary);
}

.check-transactions a {
	color: var(--ion-text-color);
	font-weight: bold;
}

.check-transactions h3 {
	font-size: 1.2rem;
	text-decoration: underline;
	text-decoration-color: #b3b3b3;
	text-underline-offset: 0.9rem;
}

ion-item {
	--padding-start: 0;
	--padding-end: 0;
}
/* #endregion check-transactions */
</style>
