<script setup>
import { ref, onMounted } from 'vue';
import {
	IonButton,
	IonList,
	IonItem,
	IonLabel,
	IonChip,
	toastController,
	loadingController,
} from '@ionic/vue';
import moneykit from '@moneykit/connect';
import { trackEvent } from '@/utils/analytics';
import { getUser, supabase } from '@/utils/supabase';

const user = ref(null);
const links = ref(null);
const isLinking = ref(false);

const createLinkSession = async () => {
	const response = await fetch(
		`${process.env.VUE_APP_SERVER_BACKEND_URL}/moneykit/linking/session`,
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				userId: user.value.id,
				email: user.value.email,
				//existing_link_id: undefined, // TODO
			}),
		}
	);
	const data = await response.json();
	if (!response.ok) throw data;
	console.log('data: ', data);
	return data;
};

const startLink = async (onSuccess) => {
	console.log("hi I'm starting my link!");
	const { link_session_token } = await createLinkSession();
	const mk = new moneykit();
	console.log('hi moneykit client has been created');
	console.log('the link session token is: ', link_session_token);
	mk.link(link_session_token, onSuccess);
};

const exchangeToken = async (token) => {
	console.log("hi I'm exchanging my token!");
	return fetch(
		`${process.env.VUE_APP_SERVER_BACKEND_URL}/moneykit/linking/token`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ exchangeable_token: token }),
		}
	)
		.then((response) => response.json())
		.then((data) => {
			console.log('Exchange token response data:', data);
			return data;
		});
};

const onClickStart = async () => {
	trackEvent('MoneyKit Link started via click');
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 8000 });
	isLinking.value = true;

	try {
		await loader.present();

		await startLink(async (exchangeableToken) => {
			const response = await exchangeToken(exchangeableToken);
			trackEvent('MoneyKit Link completed successfully', {
				link_id: response.link_id,
			});
			isLinking.value = false;
			fetchLinks();
		});
	} catch (error) {
		toast.message = error.message || 'An unexpected error occurred.';
		await toast.present();
		trackEvent('MoneyKit Link failed', error);
	} finally {
		loader.dismiss();
	}
};

const fetchLinks = async () => {
	const loader = await loadingController.create();
	const toast = await toastController.create({ duration: 8000 });

	try {
		await loader.present();
		const { data, error } = await supabase
			.from('moneykit_items')
			.select()
			.eq('user_id', user.value.id);

		if (error) throw error;

		links.value = data;
	} catch (error) {
		toast.message = error.message;
		await toast.present();
	} finally {
		loader.dismiss();
	}
};

onMounted(async () => {
	user.value = await getUser();
	fetchLinks();
});
</script>

<template>
	<div>
		<ion-button @click="onClickStart">
			{{ isLinking ? 'Link In Progress...' : 'Start Link' }}
		</ion-button>
		<ion-list v-if="links">
			<ion-item v-for="link in links" :key="link.link_id">
				<ion-label>
					{{ link.institution_name || link.link_id }}
				</ion-label>
				<ion-chip slot="end">
					{{ link.state }}
				</ion-chip>
			</ion-item>
		</ion-list>
	</div>
</template>

<style></style>
